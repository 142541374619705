'use client';
import { FileUploadFormatted } from '@/types/files';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { UploadContext } from '../Providers/UploadProvider';
import Loader from '../Loader/Loader';
import classNames from 'classnames';
import Image from 'next/image';
import { typeDocumentToFilepath } from '@/helpers/library/uploadFile';

export default function UploadingSnackBarFile({
    file,
    index,
}: {
    file: FileUploadFormatted;
    index: number;
}) {
    const { filesUploading, setFilesUploading, setSnackbarUploadingOpen } =
        useContext(UploadContext);
    return (
        <div className='flex h-12 flex-row items-center justify-between px-4'>
            <div className={'flex flex-row'}>
                {!file?.uploaded ? (
                    <FontAwesomeIcon
                        icon={faClose}
                        height={16}
                        width={16}
                        max={16}
                        color={'black'}
                        onClick={() => {
                            setFilesUploading(
                                filesUploading.filter(
                                    (_obj, ind) => ind !== index
                                )
                            );
                        }}
                        className='cursor-pointer rounded-lg text-xl text-black'
                    />
                ) : null}
                <Image
                    priority
                    src={typeDocumentToFilepath[file.type]}
                    alt='Pdf icon'
                    width={16}
                    height={16}
                    className='ml-1 mr-2 mt-0.5 max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] object-fill'
                />
                <h2 className='text-left'>{file.name}</h2>
            </div>
            <div className='mx-1 hidden h-11 w-11 items-center justify-center'>
                <Loader />
            </div>
            <div
                className={classNames(
                    'mr-2 flex items-center justify-center rounded-full p-0.5',
                    {
                        'bg-green-700': file?.uploaded && !file?.uploading,
                        'bg-red-600': !file.uploaded && !file?.uploading,
                    }
                )}
            >
                {file?.uploaded && !file.uploading ? (
                    <FontAwesomeIcon
                        icon={faCheck}
                        height={20}
                        width={20}
                        max={20}
                        color={'white'}
                        className='text-lg text-white hover:rounded-full hover:bg-white'
                    />
                ) : null}
                {file?.uploading ? <Loader /> : null}
                {!file?.uploaded && !file.uploading ? (
                    <FontAwesomeIcon
                        icon={faClose}
                        height={18}
                        width={18}
                        color={'red'}
                        onClick={() => setSnackbarUploadingOpen(false)}
                        className='max-h-[18px] min-h-[18px] min-w-[18px] max-w-[18px] cursor-pointer text-white'
                    />
                ) : null}
            </div>
        </div>
    );
}
