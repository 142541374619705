import React from 'react';
import {
    AuthorizedFormatType,
    FileUploadFormatted,
    UploadData,
} from '@/types/files';
import { UploadableFile } from '@/types/connectors';
import apiDocuments from '@/requests/apis/documents';

const singleFileUpload = async (
    file: any,
    index: number,
    currentPath: string,
    setFilesUploading: React.Dispatch<
        React.SetStateAction<FileUploadFormatted[]>
    >,
    setErrorUploadQuota: React.Dispatch<
        React.SetStateAction<null | 'files' | 'size'>
    >,
    org_id?: string
) => {
    const uploadFileStatusChange = (success: boolean) => {
        setFilesUploading((prev: FileUploadFormatted[]) => {
            return prev.map((file: FileUploadFormatted, id: number) => {
                if (id === index) {
                    return {
                        ...file,
                        uploading: false,
                        uploaded: success,
                    };
                } else return file;
            });
        });
    };
    try {
        const uploadData = getUploadData({ file, currentPath, org_id });
        let responsePresigned;
        if (file instanceof File) {
            responsePresigned = await uploadLocalFile(file, uploadData);
        } else {
            responsePresigned = await apiDocuments.uploadFile({
                file,
                uploadData,
            });
        }
        await apiDocuments.confirmUpload({
            fileId: responsePresigned.fileData?.id,
        });
        uploadFileStatusChange(true);
        return { file: responsePresigned?.fileData };
    } catch (e) {
        uploadFileStatusChange(false);
        if (e instanceof Error) {
            if (e.message === 'Quota files exceeded') {
                setErrorUploadQuota('files');
            } else if (e.message === 'Quota size exceeded') {
                setErrorUploadQuota('size');
            }
        }
        throw e;
    }
};

async function uploadLocalFile(file: File, uploadData: UploadData) {
    try {
        const responsePresigned = await apiDocuments.getPresignedDocumentUrl({
            file,
            uploadData,
        });
        await fetch(responsePresigned.url, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type,
            },
            body: file,
        });
        return responsePresigned;
    } catch (e) {
        console.log('error', e);
        throw e;
    }
}

function getUploadData({
    file,
    currentPath,
    org_id,
}: {
    file: UploadableFile;
    currentPath: string;
    org_id?: string;
}): UploadData {
    return {
        is_shared: !!org_id,
        path: getUploadedFilePath(currentPath, file),
    };
}

const getUploadedFilePath = (currentPath: string, file: UploadableFile) => {
    if (file instanceof File) {
        const pathWithoutFileName = removeFileNameFromWebkitRelativePath(
            file.webkitRelativePath
        );
        return `${decodeURIComponent(currentPath)}${
            pathWithoutFileName.length > 0
                ? `/${decodeURIComponent(pathWithoutFileName)}`.replace(
                      '//',
                      '/'
                  )
                : '/'
        }`;
    }
    return `${currentPath}/`;
};

const removeFileNameFromWebkitRelativePath = (webkitRelativePath: string) => {
    if (webkitRelativePath.length > 0) {
        const pathToArray = webkitRelativePath.split('/');
        const pathWithoutFileName = pathToArray
            .slice(0, pathToArray.length - 1)
            .join('/');
        return pathWithoutFileName;
    }
    return '';
};

const authorizedFormats = [
    '.pdf',
    '.docx',
    '.doc',
    '.PDF',
    '.odt',
    '.DOCX',
    '.DOC',
    '.ODT',
] as const;

const formatToMime: { [key: string]: string } = {
    '.pdf': 'application/pdf',
    '.docx':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.doc': 'application/msword',
    '.odt': 'application/vnd.oasis.opendocument.text',
};
const mimeToTypeDocument: { [key: string]: string } = {
    'application/pdf': 'pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',
    'application/msword': 'doc',
    'application/vnd.oasis.opendocument.text': 'odt',
};
const validMimeTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.oasis.opendocument.text',
];
const isValidMimeType = (mimeType: string) => {
    return validMimeTypes.includes(mimeType);
};
const typeDocumentToMime: { [key: string]: string } = {
    pdf: 'application/pdf',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    odt: 'application/vnd.oasis.opendocument.text',
};
const typeDocumentToFilepath: { [key: string]: string } = {
    pdf: '/images/V2/icon_pdf.svg',
    odt: '/images/V2/icon_docx.svg',
    docx: '/images/V2/icon_docx.svg',
    doc: '/images/V2/icon_docx.svg',
};
const typeDocumentTableToFilepath: { [key: string]: string } = {
    '2_pdf': '/images/V2/icon_pdf.svg',
    '4_odt': '/images/V2/icon_docx.svg',
    '3_docx': '/images/V2/icon_docx.svg',
    '3_doc': '/images/V2/icon_docx.svg',
};
const typeDocumentTableToReadable: { [key: string]: string } = {
    '1_folder': 'Dossiers',
    '2_pdf': 'Pdf',
    '4_odt': 'Odt',
    '3_docx': 'Docx',
    '3_doc': 'Doc',
};

const typeDocumentTableToExtension: { [key: string]: string } = {
    '2_pdf': 'pdf',
    '4_odt': 'odt',
    '3_docx': 'docx',
    '3_doc': 'doc',
};

const getMimeType = (fileName: string) => {
    const extension = fileName.split('.').pop() as
        | AuthorizedFormatType
        | undefined;
    if (!extension) {
        return null;
    }
    const reconstructedExtemsion = `.${extension.toLowerCase()}`;
    return formatToMime[reconstructedExtemsion];
};

const authorizedFormatsString = authorizedFormats.join(',');
const isPdf = (fileName: string) => {
    return fileName?.toLowerCase()?.endsWith('.pdf') ?? false;
};
const isDocx = (fileName: string) => {
    return fileName?.toLowerCase()?.endsWith('.docx') ?? false;
};

const isOdt = (fileName: string) => {
    return fileName?.toLowerCase()?.endsWith('.odt') ?? false;
};

const isDoc = (fileName: string) => {
    return fileName?.toLowerCase()?.endsWith('.doc') ?? false;
};

const isValidFile = (fileName: string) => {
    return authorizedFormats.some((format) => fileName.endsWith(format));
};

const getFileType = (file: UploadableFile): string => {
    return file?.type
        ? mimeToTypeDocument[file.type]
        : file?.name.split('.').pop()!;
};

export {
    singleFileUpload,
    authorizedFormats,
    formatToMime,
    typeDocumentToMime,
    mimeToTypeDocument,
    typeDocumentTableToFilepath,
    typeDocumentTableToReadable,
    typeDocumentToFilepath,
    typeDocumentTableToExtension,
    getMimeType,
    validMimeTypes,
    isValidMimeType,
    authorizedFormatsString,
    isPdf,
    isDocx,
    isOdt,
    isDoc,
    isValidFile,
    getFileType,
};
