'use client';
import React, { useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import { DrawerContext } from '@/components/Providers/DrawerProvider';
import AnswerDrawerSummarization from '@/components/Drawers/AnswerDrawer/Summarization/AnswerDrawerSummarization';
import AnswerDrawerMatrice from '@/components/Drawers/AnswerDrawer/Matrice/AnswerDrawerMatrice';
import AnswerDrawerSearchADB from '@/components/Drawers/AnswerDrawer/SearchADB/AnswerDrawerSearchADB';

const AnswerDrawer = () => {
    const { missionDrawerOpen, setMissionDrawerOpen, answerDrawer } =
        useContext(DrawerContext);
    const isSummarization = answerDrawer?.type_question === 'summarization';
    const isMatrice = answerDrawer?.type_question === 'matrice';
    const isSearchADB = answerDrawer?.type_question === 'search_adb';

    return (
        <Drawer
            anchor={'right'}
            transitionDuration={{
                enter: 300,
                exit: 300,
            }}
            open={missionDrawerOpen}
            onClose={() => {
                setMissionDrawerOpen(false);
            }}
        >
            {isSummarization && (
                <AnswerDrawerSummarization answerDrawer={answerDrawer} />
            )}
            {isMatrice && <AnswerDrawerMatrice answerDrawer={answerDrawer} />}
            {isSearchADB && (
                <AnswerDrawerSearchADB answerDrawer={answerDrawer} />
            )}
        </Drawer>
    );
};

export default AnswerDrawer;
