'use client';

import ArrowFollowUpBottom from '@/components/Icons/ArrowFollowUpBottom';
import JiminiButton from '@/components/common_V2/JiminiButton';
import React from 'react';

const FollowUpButtonSearchADB = ({
    onClick,
    disabled,
    isPlural,
}: {
    onClick: () => void;
    disabled: boolean;
    isPlural: boolean;
}) => {
    return (
        <JiminiButton
            onClick={onClick}
            disabled={disabled}
            variant='contained'
            backgroundColor='bg-alphaBlue3 disabled:bg-alphaGray4'
            textColor='text-solidBlue12 group-disabled:text-alphaGray10'
            text={
                isPlural
                    ? 'Repartir des documents sélectionnés'
                    : 'Repartir du document sélectionné'
            }
            className='group px-4'
            iconLeft={
                <div className='mr-2 flex h-5 w-5 items-center justify-center rounded-full bg-alphaBlue6 group-disabled:bg-alphaGray5'>
                    <ArrowFollowUpBottom
                        width={16}
                        height={16}
                        className='ml-[1px] stroke-alphaBlue11 group-disabled:stroke-alphaGray9'
                    />
                </div>
            }
        />
    );
};

export default FollowUpButtonSearchADB;
