import { useUser } from '@auth0/nextjs-auth0/client';
import {
    isLawfirmAdministrator,
    retrieveUserInfo,
} from '@/helpers/authFunctions';
import { OrganizationAuth0 } from '@/types/general';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { capitalize } from 'lodash';
import BuildingIcon from '@/components/Icons/BuildingIcon';
import DocumentIcon from '@/components/Icons/DocumentIcon';

const useJiminiUser = (library?: string) => {
    const user = useUser();
    const isOrgAdmin = isLawfirmAdministrator(user);
    const userOrganizations = retrieveUserInfo(
        user,
        'organizations'
    ) as OrganizationAuth0[];
    const user_org = userOrganizations?.length ? userOrganizations[0] : null;
    const router = useRouter();

    const librariesArray = user_org?.name
        ? [
              {
                  value: 'personal',
                  name: 'Bibliothèque personnelle',
                  onClick: () => {
                      router.push('/library/personal');
                  },
                  Icon: DocumentIcon,
              },
              {
                  value: 'lawfirm',
                  name: user_org?.display_name,
                  onClick: () => {
                      router.push(`/library/${user_org?.name}`);
                  },
                  // @TODO add icon to orgs in auth0 and try to use it here
                  Icon: BuildingIcon,
              },
          ]
        : [
              {
                  value: 'personal',
                  name: 'Bibliothèque personnelle',
                  onClick: () => {
                      router.push('/library/personal');
                  },
                  Icon: BuildingIcon,
              },
          ];

    const libraryFormatted =
        library === 'personal'
            ? 'Ma bibliothèque personnelle'
            : (user_org?.display_name ?? '');

    const formattedUserName = useMemo(() => {
        return user?.user
            ? `${capitalize(
                  (user?.user?.given_name ?? '') as unknown as string
              )} ${capitalize(
                  (user?.user?.family_name ?? '') as unknown as string
              )}`
            : 'Mon profil';
    }, [user?.user]);
    return {
        user,
        user_org,
        userOrganizations,
        isOrgAdmin,
        librariesArray,
        libraryFormatted,
        formattedUserName,
    };
};

export default useJiminiUser;
