import * as React from 'react';

const JiminiAvatar = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={29}
        height={28}
        fill='none'
        {...props}
    >
        <rect width={28} height={28} x={0.5} fill='#C4D5FF' rx={14} />
        <path
            fill='#0A0E45'
            d='M16.224 7.303c0-.7.587-1.303 1.287-1.303s1.303.602 1.303 1.303c0 .7-.586 1.286-1.303 1.286a1.29 1.29 0 0 1-1.287-1.286ZM14.271 22a4.235 4.235 0 0 1-3.01-1.261A4.238 4.238 0 0 1 10 17.729c0-2.338 1.956-4.312 4.271-4.312h1.106v2.127h-1.106c-1.142 0-2.145 1.02-2.145 2.185 0 1.164.982 2.145 2.145 2.145s2.185-.982 2.185-2.145V9.577h2.127v8.152c0 2.355-1.934 4.271-4.311 4.271h-.001Z'
        />
    </svg>
);
export default JiminiAvatar;
