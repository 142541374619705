'use client';
import Image from 'next/image';
import React from 'react';

// Julien made a design for the user avatar. Below, UserAvatarPicture is auth0-generated
// avatar image, which includes the user's initials. If the user has a picture,
// in the case we implement social logins, it will be displayed here.
// For now, we will use UserAvatarWithoutPicture with the custom design (auth0's is not customisable).
const UserAvatarPicture = ({ picture }: { picture?: string | null }) => {
    return picture ? (
        <Image
            referrerPolicy={'no-referrer'}
            src={picture}
            alt='-'
            width={28}
            height={28}
            className='min-h-[28px] min-w-[28px] rounded-full object-fill'
        />
    ) : null;
};

const UserAvatarWithoutPicture = ({ nameLetter }: { nameLetter: string }) => {
    return (
        <div className='flex h-7 w-[29px] min-w-[29px] items-center justify-center rounded-full bg-solidGray3'>
            <span className='mt-1 font-gilroyBold text-base uppercase text-solidGray9'>
                {nameLetter?.charAt(0)}
            </span>
        </div>
    );
};

export { UserAvatarPicture, UserAvatarWithoutPicture };
