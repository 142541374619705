'use client';
import React, { useContext } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import Divider from '@mui/material/Divider';
import { UploadContext } from '@/components/Providers/UploadProvider';
import UploadingSnackBarFile from './UploadingSnackBarFile';

const UploadingSnackbar = () => {
    const {
        errorUploadQuota,
        setErrorUploadQuota,
        snackbarUploadingOpen,
        setSnackbarUploadingOpen,
        filesUploading,
        setFilesUploading,
    } = useContext(UploadContext);
    const uploadDoneNumber =
        filesUploading?.filter((obj) => obj.uploaded)?.length ?? 0;
    return (
        <div
            className={classNames(
                'fixed bottom-0 z-[61000] cursor-auto rounded-lg bg-white shadow-black mobile:w-full tablet:right-5 tablet:min-w-[400px]',
                {
                    'slide-in-from-bottom':
                        snackbarUploadingOpen && filesUploading,
                    'slide-out-from-top':
                        !snackbarUploadingOpen || !filesUploading,
                }
            )}
            style={{
                boxShadow:
                    'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
            }}
        >
            <div
                className={
                    'flex h-12 flex-row items-center justify-between px-4'
                }
            >
                {filesUploading?.length ? (
                    <h2>{uploadDoneNumber} uploads terminés</h2>
                ) : (
                    <>
                        {/*{errorUploadQuota === 'size' ? (*/}
                        {errorUploadQuota ? (
                            <div className='mt-0.5 inline text-left font-gilroyTitle text-red-600'>
                                <FontAwesomeIcon
                                    icon={faCloudArrowUp}
                                    height={20}
                                    width={20}
                                    className='object -mb-0.5 ml-1 mr-2 max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px] text-red-500'
                                />
                                {"Échec de l'upload :"}{' '}
                                {errorUploadQuota === 'files'
                                    ? 'limite de fichiers atteinte'
                                    : 'limite de taille atteinte'}
                            </div>
                        ) : (
                            <h2>Upload en cours</h2>
                        )}
                    </>
                )}
                <FontAwesomeIcon
                    icon={faClose}
                    height={20}
                    width={20}
                    color={'black'}
                    onClick={() => {
                        setSnackbarUploadingOpen(false);
                        if (errorUploadQuota) {
                            setErrorUploadQuota(null);
                        }
                        if (filesUploading?.length === uploadDoneNumber) {
                            setFilesUploading([]);
                        }
                    }}
                    className='mr-2 max-h-[20px] min-h-[20px] min-w-[20px] max-w-[20px] cursor-pointer text-black'
                />
            </div>
            <Divider />
            <div className='h-fit max-h-[420px] overflow-y-auto overflow-x-hidden'>
                {errorUploadQuota ? (
                    <div className='px-1 py-4'>
                        {errorUploadQuota === 'size' ? (
                            <div className='flex flex-row items-center justify-between px-4'>
                                <span className='max-w-[340px] text-left font-gilroy text-red-500'>
                                    {
                                        "Le poids des fichiers dépasse la limite journalière autorisée (500 MB) . Aucun fichier n'a été téléchargé."
                                    }
                                </span>
                            </div>
                        ) : (
                            <div className='flex flex-row items-center justify-between px-4'>
                                <span className='max-w-[340px] text-left font-gilroy text-red-500'>
                                    {
                                        "Le nombre de fichiers dépasse la limite journalière autorisée (50) . Aucun fichier n'a été téléchargé."
                                    }
                                </span>
                            </div>
                        )}
                    </div>
                ) : (
                    filesUploading?.map((file, index) => {
                        return (
                            <UploadingSnackBarFile
                                key={index}
                                file={file}
                                index={index}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
};
export default UploadingSnackbar;
