'use client';

import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const JiminiCheckbox = ({
    isSelected,
    onClick,
    shownOnHover,
    className,
    id,
}: {
    isSelected: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    shownOnHover?: boolean;
    className?: string;
    id?: string;
}) => {
    return (
        <Checkbox
            id={id}
            color='primary'
            onClick={onClick}
            className={`mx-[9px] max-h-[24px] max-w-[24px] text-solidBlue12 transition-all duration-[40ms] ${
                isSelected || !shownOnHover
                    ? 'opacity-100'
                    : 'opacity-0 group-hover:opacity-100'
            } ${className} `}
            checked={isSelected}
        />
    );
};

export default JiminiCheckbox;
