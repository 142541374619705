import { useQuery } from '@tanstack/react-query';
import { PublicConversation } from '@/types/requests';
import apiAnalyzer from '@/requests/apis/analyzer';

const useSideBarConversations = () => {
    const { isLoading, data, error, refetch } = useQuery({
        queryKey: ['conversationsList'],
        queryFn: async () => {
            const data =
                (await apiAnalyzer.getSidebarConversations()) as unknown as {
                    conversations: PublicConversation[];
                };
            return data;
        },
        gcTime: 86400,
        staleTime: 0,
        retry: 2,
    });
    return {
        data: data as { conversations: PublicConversation[] },
        isLoading,
        refetch,
        error,
    };
};

export default useSideBarConversations;
