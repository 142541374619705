import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material-nextjs@6.1.6_@emotion+cache@11.13.1_@emotion+react@11.13.3_@types+react@18.3.9__ax4yryoszokyrwmpbf3m7qptha/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@playwright+test@1.48.2_react-dom@18.2.0_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@playwright+test@1.48.2_react-dom@18.2.0_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@playwright+test@1.48.2_react-dom@18.2.0_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Changelog/ChangelogModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common_V2/DeleteFieldModal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common_V2/MobileWarningModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common_V2/UpdateFieldModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Drawers/DrawerStack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Loader/Loader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/navigationPanel/NavigationPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers/GlobalProviders.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Providers/IntercomProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/Providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/UploadDocument/UploadingSnackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/WebSocketConnection/index.tsx");
