'use client';

import React, { SetStateAction } from 'react';
import { MenuItem, Select } from '@mui/material';
import {
    sxOverrideSearchADBSortPicker,
    MenuPropsOverrideSearchADBSortPicker,
    sxOverrideSearchADBSortPickerMenuItem,
} from '@/components/Drawers/AnswerDrawer/SearchADB/Toolbar/SortPickerSearchADB/helpers/styleOverride';
import SortPickerButtonSearchADB from '@/components/Drawers/AnswerDrawer/SearchADB/Toolbar/SortPickerSearchADB/SortPickerButtonSearchADB';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';

const actions: { value: SearchADBSortBy; name: string }[] = [
    {
        value: SearchADBSortBy.Relevance,
        name: 'Pertinence',
    },
    {
        value: SearchADBSortBy.Chronological,
        name: "Date d'ajout",
    },
];

const SortPickerSearchADB = ({
    sortedBy,
    setSortedBy,
    disabled,
}: {
    sortedBy: SearchADBSortBy;
    setSortedBy: React.Dispatch<SetStateAction<SearchADBSortBy>>;
    disabled?: boolean;
}) => {
    const [isOpened, setIsOpened] = React.useState(false);
    const sortedByLabel =
        actions.find((item) => item.value === sortedBy)?.name ?? 'Pertinence';
    return (
        <Select
            id='select-sorting-searchADB-drawer'
            variant='outlined'
            className='m-0 p-0 pr-0'
            value={sortedBy}
            onClose={() => {
                setIsOpened(false);
            }}
            onOpen={() => {
                setIsOpened(true);
            }}
            renderValue={() => (
                <SortPickerButtonSearchADB
                    sortedByLabel={sortedByLabel}
                    disabled={disabled}
                    isOpened={isOpened}
                />
            )}
            MenuProps={MenuPropsOverrideSearchADBSortPicker}
            sx={sxOverrideSearchADBSortPicker}
            label='Pick a value'
            disabled={disabled}
        >
            {actions.map((item) => (
                <MenuItem
                    onClick={() => {
                        setSortedBy(item.value);
                    }}
                    key={item.value}
                    value={item.value}
                    className='py-3 pl-6 pr-8 font-gilroySemiBold text-solidBlue12 hover:bg-alphaBlue3 focus:hover:bg-alphaBlue3 active:bg-white'
                    sx={sxOverrideSearchADBSortPickerMenuItem}
                >
                    <div
                        className={`-mt-0.5 ml-0.5 mr-3 h-2.5 w-2.5 rounded-full ${item.value === sortedBy ? 'bg-solidBlue12' : ''} `}
                    />
                    {item.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SortPickerSearchADB;
