import * as React from 'react';

const JiminiLogoOneLetter = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={21}
        height={36}
        fill='none'
        {...props}
    >
        <path
            fill='#000813'
            fillOpacity={0.902}
            d='M14.59 2.93c0-1.575 1.319-2.93 2.895-2.93s2.93 1.355 2.93 2.93c0 1.577-1.318 2.896-2.93 2.896A2.9 2.9 0 0 1 14.59 2.93ZM10.194 36c-2.539 0-4.945-1.008-6.774-2.837C1.592 31.334.585 28.928.585 26.389c0-5.26 4.402-9.7 9.61-9.7h2.488v4.785h-2.489c-2.57 0-4.826 2.297-4.826 4.916 0 2.62 2.21 4.826 4.826 4.826 2.617 0 4.917-2.21 4.917-4.826V8.05h4.785V26.39c0 5.3-4.351 9.61-9.7 9.61h-.002Z'
        />
    </svg>
);
export default JiminiLogoOneLetter;
