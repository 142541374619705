'use client';
import React, { useState, useContext } from 'react';
import useJiminiUser from '@/hooks/useJiminiUser';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import UserMenuDropdown from '@/components/navigationPanel/Links/UserMenuDropdown';
import CollapseMenuButton from '@/components/navigationPanel/CollapseMenuButton';
import LinkToHelpCenter from './Links/LinkToHelpCenter';

const NavGroupBottom = () => {
    const [logoutDropdownOpen, setLogoutDropdownOpen] = useState(false);
    const { formattedUserName, user_org } = useJiminiUser();
    const { setSideBarCollapsed, sideBarCollapsed } =
        useContext(GeneralContext);

    const handleClose = () => {
        setLogoutDropdownOpen(false);
    };

    return (
        <div className='relative flex w-full flex-col justify-start gap-3.5 px-2.5 pb-6'>
            {user_org?.name === 'jimini' && (
                <a
                    href='/feature-toggles'
                    className='flex items-center justify-center px-2 py-1.5 font-gilroyTitle text-base text-alphaGray11'
                >
                    Feature toggles
                </a>
            )}
            <LinkToHelpCenter isSideBarCollapsed={sideBarCollapsed} />

            <CollapseMenuButton
                setSideBarCollapsed={setSideBarCollapsed}
                sideBarCollapsed={sideBarCollapsed}
            />

            <UserMenuDropdown
                logoutDropdownOpen={logoutDropdownOpen}
                handleClose={handleClose}
                setLogoutDropdownOpen={setLogoutDropdownOpen}
                formattedUserName={formattedUserName}
                sideBarCollapsed={sideBarCollapsed}
            />
        </div>
    );
};
export default NavGroupBottom;
