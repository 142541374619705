'use client';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    HydrationBoundary,
    QueryClient,
    QueryClientProvider,
    dehydrate,
    isServer,
} from '@tanstack/react-query';
import React, {
    ReactElement,
    useState,
    createContext,
    SetStateAction,
    Dispatch,
} from 'react';
import snippet from '@segment/snippet';
import Script from 'next/script';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useConfirmModal from '@/components/UploadDocument/ConfirmQuitModal';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';

import { materialThemeOptions } from '@/helpers/material';
import UploadProvider from '@/components/Providers/UploadProvider';
import DocumentViewerProvider from '@/components/Providers/DocumentViewerProvider';
import ResearchProvider from '@/components/Providers/ResearchProvider';
import { SearchSpaceType } from '@/types/research';
import DrawerProvider from '@/components/Providers/DrawerProvider';
import GeneralProvider from '@/components/Providers/GeneralContext';
import AnalyzerProviders from '@/components/Providers/AnalyzerProvider';
import { PublicConversation } from '@/types/requests';

type ResearchContextType = {
    searchInput: string;
    setSearchInput: Dispatch<SetStateAction<string>>;
    scrollStep: number;
    setScrollStep: Dispatch<SetStateAction<number>>;
    limit: number;
    setLimit: Dispatch<SetStateAction<number>>;
    offset: number;
    setOffset: Dispatch<SetStateAction<number>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    isLoadingMore: boolean;
    setIsLoadingMore: Dispatch<SetStateAction<boolean>>;
    noMoreSearchResults: boolean;
    setNoMoreSearchResults: Dispatch<SetStateAction<boolean>>;
    results: any | null;
    setResults: Dispatch<SetStateAction<any | null>>;
    selectedSearchSpaces: SearchSpaceType[] | null;
    setSelectedSearchSpaces: Dispatch<SetStateAction<SearchSpaceType[] | null>>;
};

type SidebarContextType = {
    sidebarConversations: PublicConversation[];
    setSidebarConversations: Dispatch<SetStateAction<PublicConversation[]>>;
    missionTrackingOpen: boolean;
    setMissionTrackingOpen: Dispatch<SetStateAction<boolean>>;
};

export const SidebarContext = createContext<SidebarContextType>({
    sidebarConversations: [],
    setSidebarConversations: () => {},
    missionTrackingOpen: false,
    setMissionTrackingOpen: () => {},
});

export const ResearchContext = createContext<ResearchContextType>({
    searchInput: '',
    setSearchInput: () => {},
    scrollStep: 0,
    setScrollStep: () => {},
    limit: 10,
    setLimit: () => {},
    offset: 0,
    setOffset: () => {},
    isLoading: false,
    setIsLoading: () => {},
    noMoreSearchResults: false,
    setNoMoreSearchResults: () => {},
    isLoadingMore: false,
    setIsLoadingMore: () => {},
    results: null,
    setResults: () => {},
    selectedSearchSpaces: null,
    setSelectedSearchSpaces: () => {},
});

function renderSnippet() {
    const opts = {
        apiKey:
            process.env.NEXT_PUBLIC_STAGE === 'prod'
                ? 'ZNK203kKlVIufoVB3YBCeIPBSgAGYVmi'
                : '26DsH2AUxtPQ5uZve9ectS8uGnt7cw5y',
        // note: the page option only covers SSR tracking.
        page: true,
    };

    if (process.env.NEXT_PUBLIC_STAGE !== 'prod') {
        return snippet.max(opts);
    }

    return snippet.min(opts);
}

// Give to a page (or a group of pages with layout) the full context of the app
// This is the main provider of the app
// Some pages can be constructed to only have some providers if needed

function makeQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                // With SSR, we usually want to set some default staleTime
                // above 0 to avoid refetching immediately on the client
                staleTime: 60 * 1000,
            },
        },
    });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
    if (isServer) {
        // Server: always make a new query client
        return makeQueryClient();
    } else {
        // Browser: make a new query client if we don't already have one
        // This is very important, so we don't re-make a new client if React
        // suspends during the initial render. This may not be needed if we
        // have a suspense boundary BELOW the creation of the query client
        if (!browserQueryClient) browserQueryClient = makeQueryClient();
        return browserQueryClient;
    }
}

const GlobalProviders = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const queryClient = getQueryClient();

    // sidebar context
    const [sidebarConversations, setSidebarConversations] = useState<
        PublicConversation[]
    >([]);
    const [missionTrackingOpen, setMissionTrackingOpen] =
        useState<boolean>(false);

    const theme = createTheme(materialThemeOptions);
    useConfirmModal();

    return (
        <UserProvider>
            <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={dehydrate(queryClient)}>
                    <SidebarContext.Provider
                        value={{
                            sidebarConversations,
                            setSidebarConversations,
                            missionTrackingOpen,
                            setMissionTrackingOpen,
                        }}
                    >
                        <GeneralProvider>
                            <AnalyzerProviders>
                                <DrawerProvider>
                                    <DocumentViewerProvider>
                                        <UploadProvider>
                                            <Script
                                                id='segment-script'
                                                dangerouslySetInnerHTML={{
                                                    __html: renderSnippet(),
                                                }}
                                            />
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                            >
                                                <ResearchProvider>
                                                    <ThemeProvider
                                                        theme={theme}
                                                    >
                                                        {children}
                                                    </ThemeProvider>
                                                </ResearchProvider>
                                            </LocalizationProvider>
                                        </UploadProvider>
                                    </DocumentViewerProvider>
                                </DrawerProvider>
                            </AnalyzerProviders>
                        </GeneralProvider>
                    </SidebarContext.Provider>
                </HydrationBoundary>
            </QueryClientProvider>
        </UserProvider>
    );
};
export default GlobalProviders;
