'use client';
import {
    AnswerMatrixRaw,
    MatrixSource,
    Paragraph,
    UseCaseType,
} from '@/types/analyzer';
import { PublicLibraryFile } from '@/types/requests';
import { usePathname } from 'next/navigation';
import {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from 'react';

type DocumentDisplayedContextType = {
    url: string;
    display_type: UseCaseType | 'reading';
    name: string;
} & Partial<PublicLibraryFile>;

type MatrixSingleAnswerDataType = {
    sources?: {} | { [p: number]: MatrixSource } | undefined;
    question: string;
    concise_answer: string;
    detailed_answer: string;
    message: AnswerMatrixRaw;
    document: PublicLibraryFile;
};

type DocumentViewerContextType = {
    matrixSingleAnswerData: MatrixSingleAnswerDataType | null;
    setMatrixSingleAnswerData: Dispatch<
        SetStateAction<MatrixSingleAnswerDataType | null>
    >;
    sidePdfLoadingError?: string | null;
    setSidePdfLoadingError: Dispatch<SetStateAction<string | null>>;
    highlightedParagraphs?: Paragraph[] | null;
    setHighlightedBbox: Dispatch<SetStateAction<any | null>>;
    currentBbox?: any | null;
    setCurrentBbox: Dispatch<SetStateAction<any | null>>;
    documentDisplayed?: DocumentDisplayedContextType | null;
    setDocumentDisplayed: Dispatch<
        SetStateAction<DocumentDisplayedContextType | null>
    >;
};

export const DocumentViewerContext = createContext<DocumentViewerContextType>({
    matrixSingleAnswerData: null,
    setMatrixSingleAnswerData: () => {},
    highlightedParagraphs: null,
    setHighlightedBbox: () => {},
    sidePdfLoadingError: null,
    setSidePdfLoadingError: () => {},
    currentBbox: null,
    setCurrentBbox: () => {},
    documentDisplayed: null,
    setDocumentDisplayed: () => {},
});

const DocumentViewerProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [matrixSingleAnswerData, setMatrixSingleAnswerData] =
        useState<MatrixSingleAnswerDataType | null>(null);
    const [documentDisplayed, setDocumentDisplayed] =
        useState<DocumentDisplayedContextType | null>(null);

    const [sidePdfLoadingError, setSidePdfLoadingError] = useState<
        string | null
    >(null);
    const [highlightedParagraphs, setHighlightedBbox] = useState<any | null>(
        null
    );
    const [currentBbox, setCurrentBbox] = useState<any | null>(null);
    const currentPath = usePathname();

    useEffect(() => {
        if (!highlightedParagraphs) {
            setCurrentBbox(null);
        }
    }, [highlightedParagraphs]);
    useEffect(() => {
        const isDashboard = currentPath.includes('/chat');
        if (documentDisplayed && isDashboard) {
            setDocumentDisplayed(null);
            setHighlightedBbox(null);
        }
    }, [currentPath, documentDisplayed, highlightedParagraphs]);
    useEffect(() => {
        const isLibrary = currentPath.includes('/library');
        if (isLibrary) {
            setDocumentDisplayed(null);
            setHighlightedBbox(null);
        }
    }, [currentPath]);

    return (
        <DocumentViewerContext.Provider
            value={{
                matrixSingleAnswerData,
                setMatrixSingleAnswerData,
                sidePdfLoadingError,
                setSidePdfLoadingError,
                highlightedParagraphs,
                setHighlightedBbox,
                currentBbox,
                setCurrentBbox,
                documentDisplayed,
                setDocumentDisplayed,
            }}
        >
            {children}
        </DocumentViewerContext.Provider>
    );
};
export default DocumentViewerProvider;
