import * as React from 'react';

const CrossIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeOpacity={1}
            strokeWidth={1.925}
            d='m12 4-8 8m0-8 8 8'
        />
    </svg>
);
export default CrossIcon;
