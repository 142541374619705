import DocumentNameShortened from '@/components/Chat/DocumentNameShortened';
import MatrixAnswerCell from '@/components/Chat/MatrixAnswerCell';
import StyledTooltip from '@/components/common/Tooltip';
import { DocumentViewerContext } from '@/components/Providers/DocumentViewerProvider';
import useDocument from '@/hooks/useDocument';
import { AnswerMatrixRaw } from '@/types/analyzer';
import { PublicLibraryFile } from '@/types/requests';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext } from 'react';
const extractQuestions = (data: AnswerMatrixRaw[]) => {
    let groupedData;
    if (!data) {
        return [];
    }
    groupedData = data?.reduce((acc: any, curr) => {
        const keyQuery = `(${curr.query}, ${curr.query_format})`;
        if (!acc[keyQuery]) {
            acc[keyQuery] = {
                query: curr.query,
                column_title: curr.column_title,
                answers: [],
            };
        }
        acc[keyQuery].answers.push(curr);
        return acc;
    }, {});

    const result = Object.entries(groupedData).map(
        //@ts-ignore
        ([keyQuery, { column_title, query, answers }]) => ({
            keyQuery,
            column_title,
            query,
            answers,
        })
    );
    return result;
};

const MatrixAnswerTable = ({
    answers,
    documents,
    isGenerating,
}: {
    answers: AnswerMatrixRaw[];
    documents: PublicLibraryFile[];
    isGenerating: boolean;
}) => {
    const questions = extractQuestions(answers);
    const postProcessedDocuments = documents?.map((document) => {
        if (answers?.length) {
            return {
                document,
                answers: answers?.filter((answer) => {
                    return document.id === answer.document.id;
                }),
            };
        } else {
            return [];
        }
    });

    const { setMatrixSingleAnswerData } = useContext(DocumentViewerContext);
    const { openDocument } = useDocument('matrice');

    const handleOnClickCell = (
        answer: AnswerMatrixRaw,
        index: number,
        type: string,
        id: string,
        document: PublicLibraryFile
    ) => {
        let rawSources = answer?.sub_sources
            ? Object.values(answer.sub_sources)
            : [];
        // some type mismatch with other sources, casting them to int
        let initialSourceParagraphs =
            rawSources?.length && rawSources[0]?.paragraphs
                ? Object.values(rawSources[0]?.paragraphs)?.map((src) => ({
                      ...src,
                      id: src?.id ?? '0',
                  }))
                : null;
        setMatrixSingleAnswerData({
            question: answer.query,
            concise_answer: answer.concise_answer,
            detailed_answer: answer.detailed_answer,
            sources: answer.sub_sources,
            message: answer,
            document,
        });
        openDocument(
            id,
            document.name,
            type,
            initialSourceParagraphs || [],
            undefined,
            true
        );
    };

    return (
        <div className='mt-12 w-full overflow-y-auto'>
            <TableContainer className='max-h-[calc(100vh-100px)]'>
                <Table>
                    <TableHead className='bg-solidGray1'>
                        <TableRow className=''>
                            <TableCell className='max-h-[40px] min-w-[256px] max-w-[256px] border border-solidGray6'>
                                <h2 className='w-fit max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap font-gilroySemiBold text-sm'>
                                    Documents
                                </h2>
                            </TableCell>
                            {questions?.map(
                                ({ query, column_title }, index) => (
                                    <TableCell
                                        className='h-[40px] max-h-[40px] min-w-[256px] max-w-[256px] border border-solidGray6'
                                        key={index}
                                    >
                                        <StyledTooltip
                                            placement='top'
                                            title={query}
                                        >
                                            <h2 className='w-fit max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap font-gilroySemiBold text-sm'>
                                                {column_title || query}
                                            </h2>
                                        </StyledTooltip>
                                    </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody className=''>
                        {(
                            postProcessedDocuments as {
                                document: PublicLibraryFile;
                                answers: AnswerMatrixRaw[];
                            }[]
                        )?.map(({ document, answers }, index) => {
                            if (!document) {
                                return;
                            }
                            const { type, name, id } = document;

                            return (
                                <TableRow
                                    key={index}
                                    className='border border-solidGray6'
                                >
                                    <TableCell className='m-0 min-w-[256px] max-w-[256px] border border-solidGray6 py-0 pl-0 pr-0'>
                                        <StyledTooltip
                                            placement='top'
                                            className='m-0 flex h-[96px]'
                                            title={name}
                                        >
                                            <div className='flex items-center justify-start'>
                                                <DocumentNameShortened
                                                    openDocument={() => {
                                                        openDocument(
                                                            id,
                                                            name,
                                                            type,
                                                            [],
                                                            undefined,
                                                            true
                                                        );
                                                    }}
                                                    iconClassname='mb-1 stroke-solidGray12'
                                                    type={type}
                                                    name={name}
                                                />
                                            </div>
                                        </StyledTooltip>
                                    </TableCell>
                                    {answers?.map((answer, index) => {
                                        const isAnswerGenerating =
                                            isGenerating &&
                                            (!answer.detailed_answer ||
                                                !answer.concise_answer);

                                        const isErrored =
                                            !isAnswerGenerating &&
                                            (!answer.detailed_answer ||
                                                !answer.concise_answer);

                                        return (
                                            <MatrixAnswerCell
                                                index={index}
                                                key={index}
                                                isGenerating={
                                                    isAnswerGenerating
                                                }
                                                isErrored={isErrored}
                                                handleOnClickCell={() => {
                                                    if (
                                                        isErrored ||
                                                        isAnswerGenerating
                                                    ) {
                                                        return null;
                                                    }
                                                    handleOnClickCell(
                                                        answer,
                                                        index,
                                                        type,
                                                        id,
                                                        document
                                                    );
                                                }}
                                                answer={answer}
                                            />
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default MatrixAnswerTable;
