export async function callApi(
    input: string | URL | globalThis.Request,
    init?: RequestInit
): Promise<Response> {
    return fetch(input, init).then((res) => {
        if (res.status === 401) {
            window.location.href = '/api/auth/login';
            return res;
        } else return res;
    });
}
