'use client';
import React from 'react';

const AnalyzerSidebarStateIndicators = ({
    isLoading,
    conversationNumber,
    error,
}: {
    isLoading: boolean;
    conversationNumber: number;
    error?: Error | null;
}) => {
    return (
        <div className='min-h-[400px] px-2 py-4'>
            {!error && conversationNumber === 0 && !isLoading ? (
                <span className='font-gilroySemiBold text-alphaGray11'>
                    {"Vous n'avez pas encore de conversation !"}
                </span>
            ) : null}
            {!!error && !conversationNumber ? (
                <span className='font-gilroySemiBold text-redError2'>
                    Une erreur est survenue
                </span>
            ) : null}
            {isLoading ? (
                <span className='font-gilroySemiBold text-alphaGray11'>
                    Chargement en cours
                </span>
            ) : null}
        </div>
    );
};

export default AnalyzerSidebarStateIndicators;
