'use client';
import React from 'react';
import Bell from '@/components/Icons/Bell';
import JiminiButton from '@/components/common_V2/JiminiButton';
import Dot from '@/components/MissionTracker/Dot';

const MissionTrackerButton = ({
    onTriggerClick,
    hasUnreadMissions,
}: {
    onTriggerClick: () => void;
    hasUnreadMissions: boolean;
}) => {
    return (
        <JiminiButton
            variant='contained'
            onClick={onTriggerClick}
            backgroundColor={`${hasUnreadMissions ? 'bg-alphaBlue3' : 'bg-transparent'} hover:bg-alphaGray5`}
            className='relative min-w-[40px] px-0'
            iconLeft={
                <>
                    <Bell className='stroke-solidGray12' />
                    {hasUnreadMissions ? (
                        <Dot className='absolute right-1 top-1 bg-solidBlue11' />
                    ) : null}
                </>
            }
        />
    );
};

export default MissionTrackerButton;
