'use client';
import React, { ReactElement, useEffect } from 'react';
import { ResearchContext } from '@/components/Providers/GlobalProviders';
import { CommonResultType, SearchSpaceType } from '@/types/research';
import { searchSpacesDefault } from '@/helpers/research';

const ResearchProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [searchInput, setSearchInput] = React.useState<string>('');

    const [limit, setLimit] = React.useState<number>(5);
    const [offset, setOffset] = React.useState<number>(0);
    const [scrollStep, setScrollStep] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingMore, setIsLoadingMore] = React.useState<boolean>(false);
    const [noMoreSearchResults, setNoMoreSearchResults] =
        React.useState<boolean>(false);
    const [results, setResults] = React.useState<CommonResultType[]>([]);
    const [selectedSearchSpaces, setSelectedSearchSpaces] = React.useState<
        SearchSpaceType[] | null
    >(searchSpacesDefault);
    useEffect(() => {
        setScrollStep(0);
    }, []);

    return (
        <ResearchContext.Provider
            value={{
                scrollStep,
                setScrollStep,
                searchInput,
                setSearchInput,
                limit,
                setLimit,
                offset,
                setOffset,
                isLoading,
                setIsLoading,
                isLoadingMore,
                setIsLoadingMore,
                noMoreSearchResults,
                setNoMoreSearchResults,
                results,
                setResults,
                selectedSearchSpaces,
                setSelectedSearchSpaces,
            }}
        >
            {children}
        </ResearchContext.Provider>
    );
};

export default ResearchProvider;
