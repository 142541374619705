'use client';
import React from 'react';

const SoonBadge = () => {
    return (
        <div className='mr-2 rounded-[6px] bg-alphaGray3 px-2 py-0.5'>
            <span className='font-gilroySemiBold text-sm text-alphaGray11'>
                Bientôt
            </span>
        </div>
    );
};

export default SoonBadge;
