'use client';

import React, { useMemo } from 'react';
import { formatSummarizationQuestion } from '@/helpers/analyzerMissions';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';

const SummarizationUserMessage = ({
    message,
}: {
    message: PublicMessageWithPreviousAndFiles;
}) => {
    const postProcessedQuestion = useMemo(() => {
        if (message.docs.length) {
            return formatSummarizationQuestion(1, message.docs ?? []);
        } else {
            return '';
        }
    }, [message]);

    return (
        <>
            <span className='font-gilroyMedium text-base text-alphaGray11'>
                {postProcessedQuestion}
            </span>
        </>
    );
};

export default SummarizationUserMessage;
