'use client';
import React, { useContext } from 'react';
import JiminiLogo from '@/components/Icons/JiminiLogo';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import JiminiLogoOneLetter from '@/components/Icons/JiminiLogoOneLetter';
import Link from 'next/link';
import MissionTracker from '@/components/MissionTracker/MissionTracker';
import usePostMissionSubmit from '@/hooks/usePostMissionSubmit';

const SideBarLogoNotifArea = () => {
    const { sideBarCollapsed } = useContext(GeneralContext);
    const { resetPromptModule } = usePostMissionSubmit();

    return (
        <div className='absolute top-0 h-20 pl-2.5 transition-all delay-200'>
            <div
                className={`flex h-[80px] flex-row items-center justify-between pl-[1px] ${
                    sideBarCollapsed
                        ? 'w-[40px] opacity-0'
                        : 'w-[220px] opacity-100'
                } origin-left transition-all duration-200 ease-in-out`}
            >
                <Link
                    href='/'
                    prefetch={true}
                    onClick={() => resetPromptModule()}
                >
                    <JiminiLogo
                        width={92}
                        height={36}
                        className='fill-solidBlue12'
                    />
                </Link>
                <MissionTracker />
            </div>
            <div
                className={`absolute top-0 flex h-[80px] w-fit flex-row items-center justify-between ${
                    sideBarCollapsed ? 'opacity-100' : 'opacity-0'
                } transition-all duration-200 ease-in-out`}
            >
                <JiminiLogoOneLetter className='fill-solidBlue12' />
            </div>
        </div>
    );
};

export default SideBarLogoNotifArea;
