'use client';
import useDocument from '@/hooks/useDocument';

const LinkTagInMarkdown = (input: { params: string; files: any[] }) => {
    const { openDocument } = useDocument('summarization');

    if (input?.params) {
        const params = input.params.split('||');
        const text = params[0];
        const document_number = parseInt(params[1]);
        const page = parseInt(params[2]);
        const document =
            input?.files?.length <= document_number
                ? input?.files[document_number - 1]
                : null;
        return (
            <span
                className='cursor-pointer font-gilroyMedium text-2xl text-primary1 underline hover:text-primary3'
                onClick={() => {
                    openDocument(
                        document?.id ?? '',
                        document?.name ?? '',
                        'pdf',
                        [
                            {
                                bbox: {
                                    bottom_right_x: 0,
                                    bottom_right_y: 0,
                                    top_left_x: 0,
                                    top_left_y: 0,
                                },
                                type: 'text',
                                page_num: Math.max(page - 1, 0),
                                id: 0,
                                content: text,
                                hierarchy: [],
                            },
                        ],
                        undefined
                    );
                }}
            >
                {text.replace('### ', '')}
            </span>
        );
    } else {
        return '';
    }
};

export default LinkTagInMarkdown;
