import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    PublicMessagePreviewWithPreviousAndFiles,
    PublicMessageWithPreviousAndFiles,
} from '@/types/requests';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';
import { useEffect } from 'react';
import apiChatMessage from '@/requests/apis/chatMessage';

const useSingleMessageSearchADB = (
    message: PublicMessagePreviewWithPreviousAndFiles,
    sortedBy: SearchADBSortBy
) => {
    const queryClient = useQueryClient();
    const cachedMessage = (queryClient.getQueryData([
        'message',
        message.id,
    ]) as PublicMessageWithPreviousAndFiles) ?? {
        ...message,
        text: '',
    };

    const { data, refetch } = useQuery(
        {
            queryKey: ['message-search-adb'],
            queryFn: () =>
                apiChatMessage.getSearchADBMessageById(
                    cachedMessage.id,
                    sortedBy
                ),
            gcTime: 86400,
            staleTime:
                cachedMessage.status === 'saved' &&
                cachedMessage.text &&
                cachedMessage.text.length !== 0
                    ? 86400
                    : 0,
            initialData: cachedMessage,
        },
        queryClient
    );

    useEffect(() => {
        refetch();
    }, [sortedBy]);

    return {
        data,
    };
};

export default useSingleMessageSearchADB;
