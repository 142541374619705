'use client';
import React from 'react';
import {
    AnswerDrawerCloseButton,
    AnswerDrawerTitle,
} from '@/components/Drawers/AnswerDrawer/AnswerDrawerComponents';

const AnswerDrawerSearchADBHeader = () => {
    return (
        <div className='flex flex-row items-center justify-between px-6 py-5'>
            <AnswerDrawerTitle type_question='search_adb' />
            <div className='flex flex-row items-center gap-2'>
                <AnswerDrawerCloseButton />
            </div>
        </div>
    );
};

export default AnswerDrawerSearchADBHeader;
