import * as React from 'react';

const MagnifyingGlassBars = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M18.5 18.5 21 21M4 6h16M4 12h4m-4 6h4m3-3a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z'
        />
    </svg>
);
export default MagnifyingGlassBars;
