type ToggleProps = {
    label: string;
    checked: boolean;
    onChange: () => void;
};
const Toggle = ({ label, checked, onChange }: ToggleProps) => {
    return (
        <label className='inline-flex cursor-pointer items-center'>
            <input
                type='checkbox'
                className='peer sr-only'
                checked={checked}
                onChange={onChange}
            />
            <div className="peer relative h-6 w-11 rounded-full bg-solidBlue5 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-solidBlue9 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none rtl:peer-checked:after:-translate-x-full"></div>
            <span className='ms-3 font-gilroySemiBold text-base text-solidBlue12'>
                {label}
            </span>
        </label>
    );
};

export default Toggle;
