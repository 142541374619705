import { useContext, useEffect } from 'react';
import { UploadContext } from '@/components/Providers/UploadProvider';

const useConfirmModal = () => {
    const { isUploading } = useContext(UploadContext);
    useEffect(() => {
        const preventQuitWhileUploading = (e: BeforeUnloadEvent) => {
            if (!isUploading) return;
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener('beforeunload', preventQuitWhileUploading);
        return () => {
            window.removeEventListener(
                'beforeunload',
                preventQuitWhileUploading
            );
        };
    }, [isUploading]);
};

export default useConfirmModal;
