'use client';
import React from 'react';
import DocumentNameShortened from '@/components/Chat/DocumentNameShortened';
import { PublicLibraryFileWithArgs } from '@/types/requests';
import { joinSourceParagraphs } from '@/helpers/sources';
import useDocument from '@/hooks/useDocument';
import { Source } from '@/types/analyzer';

const ResultCardSearchADB = ({
    isSelected,
    doc,
    source,
}: {
    isSelected: boolean;
    doc: PublicLibraryFileWithArgs;
    source: Source;
}) => {
    const unformattedDate = doc.created_at
        ? new Date(doc.created_at)
        : new Date();
    const formattedDate = `${unformattedDate.getDate()} ${unformattedDate.toLocaleString('fr-FR', { month: 'long' })} ${unformattedDate.getFullYear()}, ${unformattedDate.getHours()}:${unformattedDate.getMinutes()}`;
    const arrayParagraphs = Object.values(source.paragraphs);
    const display = {
        pageNumber: arrayParagraphs[0]?.page_num ?? null,
        paragraphsFlattened: joinSourceParagraphs(arrayParagraphs),
    };
    const { openDocument } = useDocument('search_adb');

    return (
        <div
            className={`flex w-full flex-col rounded-[6px] border border-alphaGray6 ${isSelected ? 'bg-alphaBlue3' : 'bg-solidBlue2'} transition-200 p-3`}
        >
            <div className='flex flex-1 flex-row items-center justify-between'>
                <div
                    className='group relative'
                    onClick={(e) => {
                        e.stopPropagation();
                        openDocument(
                            source.document_id,
                            source.title,
                            source.type_document ?? 'pdf',
                            Object.values(source.paragraphs)
                        );
                    }}
                >
                    <DocumentNameShortened
                        className='px-0'
                        iconClassname='mb-1 stroke-alphaGray10 group-hover:stroke-alphaGray11 transition-100'
                        textClassName='font-gilroyTitle text-lg text-alphaGray10 group-hover:text-alphaGray11 transition-100 whitespace-nowrap'
                        maxWidthClassName='max-w-[calc(60vw-380px)]'
                        iconProps={{
                            width: 24,
                            height: 24,
                        }}
                        type={doc.type}
                        name={doc.name}
                    />
                    <div className='transition-200 ml-[33px] h-[2px] w-[calc(100%-32px)] bg-solidGray5 group-hover:bg-solidGray6' />
                </div>
                <div className='whitespace-nowrap font-gilroyMedium text-sm text-alphaGray10'>
                    {formattedDate}
                </div>
            </div>
            <div className='text-left'>
                <p className='mb-3 mt-4 line-clamp-5 w-fit break-all font-gilroyMedium text-sm text-alphaGray10'>
                    {display.paragraphsFlattened}
                </p>
                {display.pageNumber > 0 && (
                    <span className='font-gilroy text-xs text-alphaGray10'>
                        Page {display.pageNumber}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ResultCardSearchADB;
