'use client';
import React, { SetStateAction } from 'react';
import FollowUpButtonSearchADB from '@/components/Drawers/AnswerDrawer/SearchADB/Toolbar/FollowUpButtonSearchADB';
import SortPickerSearchADB from '@/components/Drawers/AnswerDrawer/SearchADB/Toolbar/SortPickerSearchADB/SortPickerSearchADB';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';

const DrawerSearchADBToolbar = ({
    sortDisabled,
    onFollowUp,
    selectedDocsNumber,
    sortedBy,
    setSortedBy,
}: {
    sortDisabled: boolean;
    onFollowUp: () => void;
    selectedDocsNumber: number;
    sortedBy: SearchADBSortBy;
    setSortedBy: React.Dispatch<SetStateAction<SearchADBSortBy>>;
}) => {
    const followUpDisabled = selectedDocsNumber === 0;
    return (
        <div className='flex flex-row justify-end gap-2.5 py-2'>
            <FollowUpButtonSearchADB
                disabled={followUpDisabled}
                onClick={onFollowUp}
                isPlural={selectedDocsNumber > 1}
            />
            <SortPickerSearchADB
                sortedBy={sortedBy}
                setSortedBy={setSortedBy}
                disabled={sortDisabled}
            />
        </div>
    );
};

export default DrawerSearchADBToolbar;
