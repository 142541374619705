'use client';

import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import CrossIcon from '../Icons/CrossIcon';
import useMissionSelection from '@/hooks/useMissionSelection';
import { MissionsEnum } from '@/types/analyzer';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import useResponsive from '@/hooks/useResponsive';

type ChangelogModalProps = {
    keyForLocalStorage: MissionsEnum;
    picture: string;
    title: string;
    description: string;
};
function ChangelogModal({
    keyForLocalStorage,
    picture,
    title,
    description,
}: ChangelogModalProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const ref = useRef<HTMLDialogElement>(null);
    const { onSelectMission } = useMissionSelection();
    const { isMobile } = useResponsive();

    useEffect(() => {
        const changelogToHide = localStorage.getItem('changelogToHide');
        setIsModalOpen(
            changelogToHide === null
                ? true
                : !JSON.parse(changelogToHide).includes(keyForLocalStorage)
        );
    }, [keyForLocalStorage]);

    useEffect(() => {
        if (isModalOpen && !isMobile) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [isModalOpen, isMobile]);

    const onCloseModal = () => {
        const changelogToHide = localStorage.getItem('changelogToHide');
        const currentChangelogToHide =
            changelogToHide === null ? [] : JSON.parse(changelogToHide);
        localStorage.setItem(
            'changelogToHide',
            JSON.stringify([...currentChangelogToHide, keyForLocalStorage])
        );
        setIsModalOpen(false);
    };

    const onDiscover = () => {
        onSelectMission(keyForLocalStorage);
        onCloseModal();
    };

    return (
        isModalOpen && (
            <dialog
                ref={ref}
                className='z-10 flex min-h-[682px] w-[620px] flex-col items-center gap-4 rounded-2xl px-5 py-3 outline-none'
                onCancel={onCloseModal}
            >
                <div className='flex w-full justify-end'>
                    <button
                        onClick={onCloseModal}
                        className='rounded-full p-2 outline-none hover:bg-alphaGray3 focus:bg-alphaGray3'
                    >
                        <CrossIcon
                            height={20}
                            width={20}
                            className='stroke-solidBlue12'
                        />
                    </button>
                </div>
                <h1 className='font-gilroySemiBold text-3xl text-solidBlue12'>
                    Nouvelle fonctionnalité !
                </h1>
                <Image
                    referrerPolicy={'no-referrer'}
                    src={picture}
                    alt='-'
                    width={508}
                    height={281}
                    className='rounded border border-solid border-alphaGray5 object-fill'
                />
                <h2 className='font-gilroyBold text-2xl text-solidBlue12'>
                    {title}
                </h2>
                <p className='font-gilroyMedium text-base text-alphaGray11'>
                    {description}
                </p>
                <button
                    onClick={onDiscover}
                    className='mb-4 flex items-center gap-2 bg-solidBlue12 px-4 py-1.5 font-gilroySemiBold text-base text-white'
                >
                    Découvrir
                    <ArrowRightIcon
                        height={20}
                        width={20}
                        className='stroke-white'
                    />
                </button>
            </dialog>
        )
    );
}

export default ChangelogModal;
