'use client';
import React, { useMemo } from 'react';
import JiminiLink from '@/components/common_V2/JiminiLink';
import { MissionStatusType } from '@/types/analyzer';
import InfiniteCircularLoader from '@/components/Loader/InfiniteCircularLoader';
import Dot from '@/components/MissionTracker/Dot';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import WarningBordered from '@/components/Icons/WarningBordered';
import FilledSquare from '@/components/Icons/FilledSquare';
import { useStatusSpecificMissionTrackerLink } from '@/hooks/useStatusSpecificMissionTrackerLink';
import { MissionPublicType } from '@/types/requests';
import apiAnalyzer from '@/requests/apis/analyzer';

type MissionStatusTypeNoRouting = Exclude<
    MissionStatusType,
    'routing' | 'routing_summarization'
>;

type StatusSpecificMappingType = {
    [key in MissionStatusTypeNoRouting]: {
        iconRight: React.ReactElement | null;
        backgroundColor: string;
        elementsColor: string;
    };
};

const MissionTrackerLink = ({
    mission,
    index,
}: {
    mission: MissionPublicType;
    index: number;
}) => {
    const { refetchMissionTracking } = useJiminiQuery();

    const setMissionSeen = async () => {
        await apiAnalyzer.setMissionsSeen({ message_ids: [mission.id] });
        refetchMissionTracking().then();
    };

    const { MissionTypeSpecificElements, missionOverviewText } =
        useStatusSpecificMissionTrackerLink(mission);

    const statusSpecific = useMemo((): StatusSpecificMappingType => {
        // this defines the styles and elements that changes according to status.
        // The "iconRight" can contain any react element (component/html)
        // backgroundColor darkens when the mission is done and "seen"
        // elementColor is the color of the left icon, the title and overview text
        // i can't move this in the hook because it calls on JSX components.
        return {
            saved: {
                iconRight: mission.read ? null : (
                    <Dot className='mx-1.5 h-2.5 w-2.5 bg-solidBlue8' />
                ),
                backgroundColor: mission.read ? 'bg-solidGray1' : '',
                elementsColor: mission.read
                    ? 'text-solidGray9 stroke-solidGray9'
                    : 'text-solidGray12 stroke-solidGray12',
            },
            generating: {
                iconRight: (
                    <InfiniteCircularLoader
                        classes={{
                            circle: 'text-[#495DB2]',
                        }}
                        size={18}
                    />
                ),
                backgroundColor: 'bg-transparent',
                elementsColor: 'text-solidGray12 stroke-solidGray12',
            },
            error: {
                iconRight: (
                    <WarningBordered
                        className={`mr-1 stroke-red-500`}
                        viewBox='0 0 22 22'
                        height={18}
                        width={18}
                    />
                ),
                backgroundColor: 'bg-transparent',
                elementsColor: 'text-solidGray12 stroke-redError2',
            },
            interrupted: {
                iconRight: (
                    <FilledSquare
                        className='stroke-solidGray9'
                        fill={'#808FA4'}
                    />
                ),
                backgroundColor: 'bg-transparent',
                elementsColor: 'text-solidGray12 stroke-solidGray12',
            },
        };
    }, [mission.read]);

    const missionStatus = mission.status as MissionStatusTypeNoRouting;

    return (
        <div
            className={`transition-200 flex min-h-[84px] w-full flex-row items-center justify-between ${index === 0 ? '' : 'border-t'} border-alphaGray6 px-4 ${statusSpecific[missionStatus].backgroundColor} `}
        >
            <JiminiLink
                iconLeft={
                    <MissionTypeSpecificElements.Icon
                        width={20}
                        height={20}
                        viewBox='0 0 24 24'
                        className={`mr-4 ${statusSpecific[missionStatus].elementsColor}`}
                    />
                }
                href={`/new-analyzer/${mission.conversation_id}?mission_id=${mission.id}`}
                onClick={() => {
                    setMissionSeen().then();
                }}
                iconRight={<>{statusSpecific[missionStatus].iconRight}</>}
            >
                <div
                    className={`mr-12 flex flex-1 flex-col items-start justify-center ${statusSpecific[missionStatus].elementsColor} text-left`}
                >
                    <h2 className='line-clamp-1 font-gilroySemiBold text-base'>
                        {MissionTypeSpecificElements.titleMissionTracking}
                    </h2>
                    <div className='border-b border-b-solidGray3'>
                        <span className='line-clamp-1 font-gilroyMedium text-base'>
                            {missionOverviewText}
                        </span>
                    </div>
                </div>
            </JiminiLink>
        </div>
    );
};
export default MissionTrackerLink;
