'use client';
import React, { ReactElement } from 'react';
import PromptModuleProvider from '@/components/Providers/PromptModuleContext';

// new analyzer page providere
const AnalyzerProviders = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    return <PromptModuleProvider>{children}</PromptModuleProvider>;
};
export default AnalyzerProviders;
