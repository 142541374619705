'use client';
import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';

const ContextMenu = ({
    children,
    open,
    anchorRef,
    handleClose,
    ...props
}: {
    handleClose: () => void;
    anchorRef: React.RefObject<HTMLDivElement>;
    open: boolean;
    children: React.ReactNode;
} & Partial<MenuProps>) => {
    return (
        <Menu
            anchorEl={anchorRef.current}
            open={open || false}
            onClose={handleClose}
            disableScrollLock
            transitionDuration={{
                appear: 75,
                enter: 75,
                exit: 50,
            }}
            classes={{
                paper: ` border border-solidGray5 rounded-[6px] shadow-[0px_10px_25px_-3px_rgba(0,0,0,0.1)]`,
                list: 'p-0',
            }}
            {...props}
        >
            {children}
        </Menu>
    );
};
export default ContextMenu;
