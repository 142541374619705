'use client';
import React, { useState } from 'react';
import SummarizationMessage from '@/components/NewConversations/Messages/ChatbotMessage/SummarizationMessage';
import CopyIcon from '@/components/Icons/CopyIcon';
import DownloadAction from '@/components/NewConversations/Messages/ChatbotMessage/AnswersBottomToolbar/Actions/DownloadAction';
import IconButton from '@/components/NewConversations/Messages/ChatbotMessage/AnswersBottomToolbar/IconButton';
import ActionsSnackBars from '@/components/NewConversations/Messages/ChatbotMessage/AnswersBottomToolbar/Actions/ActionsSnackBars';
import useAnswerFeedback from '@/hooks/useAnswerFeedback';
import usePostProcessMessage from '@/hooks/usePostProcessMessage';
import {
    AnswerDrawerCloseButton,
    AnswerDrawerMessageWrapper,
    AnswerDrawerTitle,
} from '@/components/Drawers/AnswerDrawer/AnswerDrawerComponents';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import { removeMarkdown } from '@/helpers/markdown';

const AnswerDrawerSummarization = ({
    answerDrawer,
}: {
    answerDrawer: PublicMessageWithPreviousAndFiles;
}) => {
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const { onDownloadIconPress } = useAnswerFeedback(
        answerDrawer as PublicMessageWithPreviousAndFiles
    );

    const handleDownload = async () => {
        if (!answerDrawer?.id) {
            return;
        }
        setIsDownloading(true);
        await onDownloadIconPress();

        setIsDownloading(false);
    };

    const onCopy = async () => {
        if (!answerDrawer?.text) {
            return;
        }
        try {
            navigator.clipboard.writeText(
                await removeMarkdown(answerDrawer?.text)
            );
        } catch (e) {
            return { error: 'clipboard copy failed' };
        }
        return true;
    };

    const { percentageComputed } = usePostProcessMessage(answerDrawer!);
    return (
        <>
            <div className='flex flex-row items-center justify-between border-b border-solidGray6 px-6 py-5'>
                <AnswerDrawerTitle type_question={'summarization'} />
                <div className='flex flex-row items-center gap-2'>
                    <>
                        <IconButton
                            icon={
                                <CopyIcon
                                    width={22}
                                    height={22}
                                    viewBox='0 0 24 24'
                                    className='stroke-solidBlue12'
                                />
                            }
                            onClick={onCopy}
                        />
                        <DownloadAction
                            typeQuestion={'summarization'}
                            onDownloadIconPress={handleDownload}
                            isDownloading={downloadError}
                        />
                        <ActionsSnackBars
                            isDownloading={isDownloading}
                            downloadError={downloadError}
                        />
                    </>
                    <AnswerDrawerCloseButton />
                </div>
            </div>
            <AnswerDrawerMessageWrapper>
                <SummarizationMessage
                    message={answerDrawer!}
                    percentage={percentageComputed || 1}
                />
            </AnswerDrawerMessageWrapper>
        </>
    );
};

export default AnswerDrawerSummarization;
