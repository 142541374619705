import { UploadData } from '@/types/files';
import { callApi } from '../callApi';
import { PublicLibraryFile } from '@/types/requests';
import { FileFromConnector } from '@/types/connectors';
import { typeDocumentToMime } from '@/helpers/library/uploadFile';

/* eslint-disable max-lines */
const apiDocuments = {
    getPresignedDocumentUrl: async ({
        file,
        uploadData,
    }: {
        file: File;
        uploadData: UploadData;
    }): Promise<{
        fileData: PublicLibraryFile;
        url: string;
        success: boolean;
        message: string;
    }> => {
        const payload = {
            data: uploadData,
            file: {
                name: file.name,
                size: file.size,
                type: file.type,
            },
        };
        return callApi(`/api/library/getSigned`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then((res) => {
            if (res?.ok) return res.json();
            else throw new Error(res.statusText);
        });
    },
    uploadFile: async ({
        file,
        uploadData,
    }: {
        file: FileFromConnector;
        uploadData: UploadData;
    }): Promise<{
        fileData: PublicLibraryFile;
        success: boolean;
        message: string;
    }> => {
        const payload = {
            data: uploadData,
            file,
        };

        return callApi(`/api/library/upload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then((res) => {
            if (res?.ok) return res.json();
            else throw new Error(res.statusText);
        });
    },
    confirmUpload: async ({ fileId }: { fileId?: string }): Promise<void> => {
        return callApi(`/api/library/upload/validate/${fileId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (!res?.ok) throw new Error(res.statusText);
        });
    },
    createFolder: async ({
        path,
        library,
    }: {
        library: 'private' | 'shared';
        path: string;
    }): Promise<undefined | null | { newFolderId: string }> => {
        return callApi(`/api/library/folder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                library_name: 'default',
                library_mode: library,
                path,
            }),
        }).then((res) => {
            if (!res?.ok) {
                return null;
            }
            return res.json();
        });
    },
    rename: async ({ id, name }: { id: string; name: string }) => {
        return callApi(`/api/library/folder/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
            }),
        }).then((res) => {
            if (!res?.ok) throw new Error(res.statusText);
        });
    },
    moveElement: async ({
        sourceId,
        destId,
        libraryType,
    }: {
        sourceId: readonly string[];
        destId: string | null | undefined;
        libraryType: 'private' | 'shared';
    }) => {
        return callApi(`/api/library/moveFile`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target: destId || null,
                source: sourceId,
                library_type: libraryType,
                library_name: 'default',
            }),
        }).then((res) => {
            if (!res?.ok) throw new Error(res.statusText);
        });
    },
    getLibraryByPath: async ({
        library,
        path,
    }: {
        library: 'private' | 'shared';
        path: string;
    }) => {
        return callApi(`/api/library/${library}/default/${path}`, {
            method: 'GET',
            cache: 'no-store',
        }).then((res) => res.json());
    },
    getLibrary: async ({
        library,
        dropCache,
    }: {
        library: 'private' | 'shared';
        dropCache?: boolean;
    }) => {
        return callApi(`/api/library/all/${library}/default`, {
            method: 'GET',
            cache: dropCache ? 'no-store' : 'default',
            next: {
                revalidate: dropCache ? 0 : 300,
            },
        })
            .then((res) => res.json())
            .catch((err) => console.log('error', err));
    },
    getLibraryFolders: async ({
        library,
        dropCache,
    }: {
        library: 'private' | 'shared';
        dropCache?: boolean;
    }) => {
        return callApi(`/api/library/all/${library}/default/folders`, {
            method: 'GET',
            cache: dropCache ? 'no-store' : 'default',
            next: {
                revalidate: dropCache ? 0 : 300,
            },
        })
            .then((res) => res.json())
            .catch((err) => console.log('error', err));
    },
    searchLibrary: async ({
        library,
        searchValue,
    }: {
        library: 'private' | 'shared';
        searchValue: string;
    }) => {
        return callApi(`/api/library/all/${library}/default/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-store',
            body: JSON.stringify({ searchValue }),
        })
            .then((res) => res.json())
            .catch((err) => console.log('error', err));
    },
    getMagicWandResultUrl: async ({
        libraryFileId,
    }: {
        libraryFileId: string;
    }) => {
        return callApi(`/api/missions/magic_wand/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-store',
            body: JSON.stringify({ libraryFileId }),
        })
            .then((response) => response.json())
            .catch(() => {
                return null;
            });
    },
    getTranslationResultUrl: async ({
        libraryFileId,
    }: {
        libraryFileId: string;
    }) => {
        return callApi(`/api/missions/translation/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-store',
            body: JSON.stringify({ libraryFileId }),
        })
            .then((response) => response.json())
            .catch(() => {
                return null;
            });
    },
    getAnonymizationResultUrl: async ({
        libraryFileId,
    }: {
        libraryFileId: string;
    }) => {
        return callApi(`/api/missions/anonymization/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-store',
            body: JSON.stringify({ libraryFileId }),
        })
            .then((response) => response.json())
            .catch(() => {
                return null;
            });
    },
    getSignedDocument: async ({
        id,
        contentType,
    }: {
        id: string;
        contentType: string;
    }) => {
        return callApi(`/api/library/file/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': contentType,
            },
        }).then((res) => res.json());
    },
    getSignedDocumentForDownload: async ({
        id,
        contentType,
    }: {
        id: string;
        contentType: string;
    }) => {
        return callApi(`/api/library/file/${id}/download`, {
            method: 'GET',
            headers: {
                'Content-Type': typeDocumentToMime[contentType],
            },
        }).then((response) => response.json());
    },
    deleteDocument: async ({ id }: { id: string }) => {
        return callApi(`/api/library/file/${id}`, {
            method: 'DELETE',
        }).then((res) => {
            if (!res?.ok) throw new Error(res.statusText);
        });
    },
    retryEmbedding: async ({ file_id }: { file_id: string }) => {
        return callApi(`/api/library/file/${file_id}/retryEmbed`, {
            method: 'GET',
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    downloadAnswerFile: async ({
        markdown_content,
        target_format,
        name,
        onError,
    }: {
        markdown_content: string;
        target_format: 'pdf' | 'docx'; //
        name: string;
        onError?: () => void;
    }) => {
        return callApi(`/api/chat_message/download`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                markdown_content,
                target_format,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res;
            })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.${target_format}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                onError && onError();
            });
    },
    downloadAnswerFileMatrice: async ({
        message_id,
        target_format,
        name,
        onError,
    }: {
        message_id: string;
        target_format: 'xlsx' | 'concise' | 'detailed'; //
        name: string;
        onError?: () => void;
    }) => {
        return callApi(`/api/chat_message/${message_id}/download/dem`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target_format,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res;
            })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = name;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => {
                onError && onError();
            });
    },
};

export default apiDocuments;
