'use client';
import React from 'react';
import JiminiCheckbox from '@/components/common_V2/JiminiCheckbox';
import ResultCardSearchADB from '@/components/Drawers/AnswerDrawer/SearchADB/ResultCardSearchADB';
import { PublicLibraryFileWithArgs } from '@/types/requests';
import { Source } from '@/types/analyzer';

const SearchADBResultList = ({
    docs,
    sources,
    selectedDocs,
    setSelectedDocs,
}: {
    docs: PublicLibraryFileWithArgs[];
    sources: Source[];
    selectedDocs: string[];
    setSelectedDocs: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
    const onSelectExtract = (docId: string) => {
        setSelectedDocs((previouslySelectedDocs) =>
            previouslySelectedDocs.includes(docId)
                ? previouslySelectedDocs.filter(
                      (selected) => selected !== docId
                  )
                : [...previouslySelectedDocs, docId]
        );
    };
    const getDocByKey = (documentId: string) => {
        return docs.find((doc) => doc.id === documentId)!;
    };

    return (
        <>
            {sources.map((source, id) => {
                const isSelected =
                    selectedDocs.find(
                        (selected) => selected === source.document_id
                    ) !== undefined;
                return (
                    <div
                        key={id}
                        className='flex cursor-pointer flex-row items-center pr-3'
                        onClick={() => onSelectExtract(source.document_id)}
                    >
                        <JiminiCheckbox
                            className='p-1'
                            isSelected={isSelected}
                        />
                        <ResultCardSearchADB
                            doc={getDocByKey(source.document_id)}
                            source={source}
                            isSelected={isSelected}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default SearchADBResultList;
