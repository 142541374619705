'use client';
import React, { useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import dynamic from 'next/dynamic';
import { DocumentViewerContext } from '@/components/Providers/DocumentViewerProvider';
import classNames from 'classnames';
const NoSSRApryseViewerComponent = dynamic(
    () => import('../Drawers/ApryseViewerComponent'),
    { ssr: false }
);

const ApryseDrawer = () => {
    const {
        documentDisplayed,
        setDocumentDisplayed,
        setCurrentBbox,
        setHighlightedBbox,
        setMatrixSingleAnswerData,
    } = useContext(DocumentViewerContext);

    const onClose = () => {
        setDocumentDisplayed(null);
        setCurrentBbox(null);
        setHighlightedBbox(null);
        setTimeout(() => {
            setMatrixSingleAnswerData(null);
        }, 500);
    };

    return (
        <Drawer
            anchor={'right'}
            transitionDuration={{
                enter: 300,
                exit: 300,
            }}
            classes={{
                paper: 'mobile:min-w-[100vw] tablet:min-w-[64vw]',
            }}
            open={!!documentDisplayed}
            onClose={onClose}
        >
            <div
                className={classNames(
                    'flex h-full w-full flex-col mobile:top-[88px]',
                    {
                        'bg-transparent': !documentDisplayed,
                        'bg-white': !!documentDisplayed,
                    }
                )}
            >
                <NoSSRApryseViewerComponent onClose={onClose} />
            </div>
        </Drawer>
    );
};

export default ApryseDrawer;
