import { useQuery } from '@tanstack/react-query';
import { MissionPublicType } from '@/types/requests';
import apiAnalyzer from '@/requests/apis/analyzer';

const useMissionTracker = () => {
    const {
        refetch,
        data,
        error,
        isLoading: isApiLoading,
    } = useQuery({
        queryKey: ['missionTracker'],
        queryFn: async () => {
            return apiAnalyzer.getMissionTracker();
        },
        gcTime: 86400,
        staleTime: 0,
        refetchOnWindowFocus: true,
    });

    return {
        refetch,
        data: data as MissionPublicType[],
        error,
        isApiLoading,
    };
};

export default useMissionTracker;
