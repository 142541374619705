import { ConversationMessageGetById } from '@/types/general';
import { callApi } from '../callApi';

const apiConversationMessage = {
    deleteConversationById: async ({
        conversationId,
    }: ConversationMessageGetById) => {
        return callApi(`/api/conversations/${conversationId}`, {
            method: 'DELETE',
        });
    },
};

export default apiConversationMessage;
