import React from 'react';

const SearchADBUserMessage = ({ text }: { text: string }) => {
    return (
        <div className='font-gilroyMedium text-solidGray11'>
            <div className='lg:prose-xl prose max-w-full whitespace-pre-wrap'>
                {text}
            </div>
        </div>
    );
};

export default SearchADBUserMessage;
