'use client';
import { Backdrop, Modal } from '@mui/material';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import React, { useContext } from 'react';
import Image from 'next/image';
import JiminiButton from '@/components/common_V2/JiminiButton';
import { useRouter } from 'next/navigation';
import Slide from '@mui/material/Slide';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import ModalErrorSnackbar from '@/components/common_V2/ModalErrorSnackbar';
import InfiniteLoadingCircle from '@/components/common_V2/InfiniteLoadingCircle';
import apiConversationMessage from '@/requests/apis/conversationMessage';
import apiDocuments from '@/requests/apis/documents';

const modalPreset = {
    conversation: {
        title: 'Supprimer cette conversation ?',
        titlePlural: () => '',
        fieldLabel:
            'Cette action est irréversible. Une fois supprimée, cette conversation ne pourra plus être récupérée.',
    },
    conversation_threads_page: {
        title: 'Supprimer cette conversation ?',
        titlePlural: () => '',
        fieldLabel:
            'Cette action est irréversible. Une fois supprimée, cette conversation ne pourra plus être récupérée.',
    },
    file: {
        title: 'Supprimer ce fichier ?',
        titlePlural: (fileNumber: number) =>
            `Supprimer ces ${fileNumber} fichiers ?`,
        fieldLabel:
            'Cette action est irréversible. Une fois supprimé, ce fichier ne pourra plus être récupéré.',
    },
};

const DeleteFieldModal = () => {
    const { deleteModal, setDeleteModal } = useContext(GeneralContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const { refetchLibraryByPath, refetchConversations, refetchDataroom } =
        useJiminiQuery();

    const router = useRouter();
    const onDeleteConversation = async (fromThreadsPage: boolean) => {
        if (!deleteModal?.idToDelete) {
            setError('Une erreur est survenue');
            setIsLoading(false);
            return;
        }
        try {
            const deleted = await apiConversationMessage.deleteConversationById(
                { conversationId: deleteModal?.idToDelete }
            );
            if (deleted) {
                setDeleteModal({
                    deleteConversationModalOpen: false,
                    initialValue: '',
                    fieldType: 'conversation',
                    queryKey: '',
                    idToDelete: '',
                    idsToDelete: [],
                });
                setIsLoading(false);
                refetchConversations();
                if (!fromThreadsPage) {
                    router.push('/');
                }
            }
        } catch (e) {
            setIsLoading(false);
            setError('Une erreur est survenue');
            console.log('error', e);
        }
    };

    const onDeleteFiles = async (ids: string[], queryKey: string) => {
        try {
            await Promise.all(
                ids.map(async (id, index) => {
                    return apiDocuments.deleteDocument({
                        id,
                    });
                })
            );
            refetchDataroom();
            await refetchLibraryByPath(queryKey);
            onClose();
            setIsLoading(false);
        } catch (e) {}
        setIsLoading(false);
    };

    const onDelete = () => {
        setIsLoading(true);
        setError(null);
        if (
            deleteModal?.fieldType === 'conversation' ||
            deleteModal?.fieldType === 'conversation_threads_page'
        ) {
            onDeleteConversation(
                deleteModal.fieldType === 'conversation_threads_page'
            );
        } else if (deleteModal?.idsToDelete) {
            onDeleteFiles(deleteModal?.idsToDelete, deleteModal?.queryKey);
        }
    };

    const onClose = () => {
        if (deleteModal) {
            setDeleteModal({
                ...deleteModal,
                deleteConversationModalOpen: false,
            });
            setTimeout(() => {
                setDeleteModal({
                    deleteConversationModalOpen: false,
                    initialValue: '',
                    fieldType: 'conversation',
                    queryKey: '',
                    idToDelete: '',
                    idsToDelete: [],
                });
            }, 500);
        }
    };
    const preset = deleteModal?.fieldType
        ? modalPreset[deleteModal.fieldType]
        : null;

    return (
        <Modal
            open={!!deleteModal?.deleteConversationModalOpen}
            // @ts-ignore
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            // className='bg-[#70707021] '
            className='flex items-center justify-center'
        >
            <Slide
                timeout={{ enter: 250, exit: 250 }}
                direction='down'
                in={!!deleteModal?.deleteConversationModalOpen}
                mountOnEnter
                unmountOnExit
            >
                <div className='z-10 flex h-[235px] w-[576px] flex-col justify-between rounded-xl bg-white p-6'>
                    <div className='flex flex-row items-center justify-between'>
                        <h1 className='text-left font-gilroySemiBold text-xl text-alphaGray12'>
                            {deleteModal?.idsToDelete &&
                            deleteModal?.idsToDelete?.length > 1 &&
                            preset?.titlePlural
                                ? preset.titlePlural(
                                      deleteModal.idsToDelete.length
                                  )
                                : preset?.title}
                        </h1>
                        <JiminiButton
                            variant='text'
                            onClick={() => {
                                onClose();
                            }}
                            backgroundColor='bg-solidBlue12'
                            className='m-0 min-w-[42px] px-0'
                            iconLeft={
                                <Image
                                    priority
                                    src={
                                        '/images/V2/tabler/icon_close_cross.svg'
                                    }
                                    alt='add icon'
                                    width={20}
                                    height={20}
                                />
                            }
                        />
                    </div>

                    {error ? <ModalErrorSnackbar error={error} /> : null}
                    <div>
                        <h1 className='mb-3 text-left font-gilroyMedium text-base text-solidGray11'>
                            {preset?.fieldLabel}
                        </h1>
                    </div>
                    <div className='flex flex-row justify-end gap-2'>
                        <JiminiButton
                            variant='contained'
                            onClick={() => onClose()}
                            text='Annuler'
                            className='w-fit'
                            backgroundColor='bg-alphaBlue3'
                            textColor='text-solidBlue12'
                        />
                        <JiminiButton
                            variant='contained'
                            onClick={onDelete}
                            text={isLoading ? '' : 'Supprimer'}
                            iconLeft={
                                isLoading ? (
                                    <InfiniteLoadingCircle className='-mb-0.5' />
                                ) : null
                            }
                            backgroundColor={'bg-danger2'}
                            className={`w-[128px]`}
                        />
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};

export default DeleteFieldModal;
