'use client';

import LinearProgressLoader from '@/components/common_V2/LinearProgressLoader';
import React from 'react';

const LinearLoaderDrawer = ({ percentage }: { percentage: number }) => {
    return (
        <>
            <span className='font-gilroySemiBold text-base text-solidBlue11'>
                {percentage}%<span className='ml-2'>Analyse en cours</span>
            </span>

            <LinearProgressLoader className='mt-1.5' percentage={percentage} />
        </>
    );
};

export default LinearLoaderDrawer;
