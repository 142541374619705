'use client';
import React from 'react';
import SearchADBUserMessage from '@/components/NewConversations/Messages/UserMessage/SearchADBUserMessage';
import DocumentsThumbnail from '@/components/common_V2/DocumentsThumbnail';
import { UserAvatarWithoutPicture } from '@/components/ui/UserAvatar';
import { useUser } from '@auth0/nextjs-auth0/client';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';

const SearchADBQuestionMessage = ({
    message,
}: {
    message: PublicMessageWithPreviousAndFiles;
}) => {
    const user = useUser();
    const firstName = (user.user?.given_name as unknown as string) ?? '';

    return (
        <div className='mt-8 flex flex-col justify-start text-left'>
            <div className='flex flex-row items-start justify-end'>
                <div className='flex flex-row gap-2 pl-2'>
                    <div className='w-full max-w-[672px] rounded-[24px] bg-solidGray3 px-5 py-4'>
                        <SearchADBUserMessage
                            text={message.followup_to_message?.text ?? ''}
                        />

                        <DocumentsThumbnail
                            className='w-fit max-w-[632px] pb-1.5 pt-4'
                            documents={message.docs}
                        />
                    </div>
                    <UserAvatarWithoutPicture nameLetter={firstName} />
                </div>
            </div>
        </div>
    );
};

export default SearchADBQuestionMessage;
