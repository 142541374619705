'use client';
import React from 'react';
import SideBarMenuIcon from '@/components/Icons/SideBarMenuIcon';
import JiminiButton from '@/components/common_V2/JiminiButton';

const CollapseMenuButton = ({
    sideBarCollapsed,
    setSideBarCollapsed,
}: {
    sideBarCollapsed: boolean;
    setSideBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    return (
        <JiminiButton
            variant='text'
            disableRipple
            onClick={() => {
                setSideBarCollapsed((prev) => !prev);
            }}
            text={'Réduire le menu'}
            textSize='text-base leading-[18px]'
            textColor={'text-alphaGray11'}
            textClassName={'overflow-hidden font-gilroyTitle '}
            className={
                'flex w-full flex-row items-center justify-start hover:bg-transparent'
            }
            shrink={sideBarCollapsed}
            iconLeft={
                <SideBarMenuIcon
                    width={24}
                    height={24}
                    className='mr-[19px] min-h-[24px] min-w-[24px] stroke-alphaGray11'
                />
            }
        />
    );
};

export default CollapseMenuButton;
