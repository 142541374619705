'use client';

import React, { useMemo } from 'react';
import AnalyzerMarkdown from '@/components/Analyzer/AnalyzerMarkdown';
import LinkTagInMarkdown from '@/components/Markdown/LinkTagInMarkdown';
import SummarizationUserMessage from '@/components/NewConversations/Messages/UserMessage/SummarizationUserMessage';
import { UserAvatarWithoutPicture } from '@/components/ui/UserAvatar';
import { useUser } from '@auth0/nextjs-auth0/client';
import JiminiAvatar from '@/components/Icons/JiminiAvatar';
import LinearLoaderDrawer from '@/components/NewConversations/Messages/ChatbotMessage/LinearLoaderDrawer';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';

// this component is only used in the drawer
const SummarizationMessage = ({
    message,
    percentage,
}: {
    message: PublicMessageWithPreviousAndFiles;
    percentage: number;
}) => {
    const memoizedText = useMemo(() => {
        return message.text?.replace(
            /^([^\n]*)( \(document (\d+), page (\d+)\))/gm,
            ':linktag{params="$1||$3||$4"}'
        );
    }, [message.text]);

    const user = useUser();
    const firstName = (user.user?.given_name as unknown as string) ?? '';

    return (
        <div className='mt-8 flex flex-col justify-start text-left'>
            <div className='flex flex-row items-start justify-end'>
                <div className='ml-2 flex flex-row'>
                    <div className='mr-2 w-full max-w-[672px] rounded-[24px] bg-solidGray3 px-5 py-4'>
                        <SummarizationUserMessage message={message} />
                    </div>
                    <UserAvatarWithoutPicture nameLetter={firstName} />
                </div>
            </div>
            <div className='mt-4 flex flex-row items-start justify-start'>
                <div className='flex items-center justify-center rounded-full'>
                    <JiminiAvatar />
                </div>
                <div className='ml-2 flex w-full flex-col'>
                    <span className='text-left font-gilroyBold text-base text-solidGray12'>
                        Jimini
                    </span>
                    {memoizedText ? (
                        <div className='lg:prose-xl prose max-w-full whitespace-pre-wrap text-justify font-gilroyMedium text-lg text-solidGray12'>
                            <AnalyzerMarkdown
                                LinkTagInMarkdown={(props) => (
                                    <LinkTagInMarkdown
                                        {...props}
                                        files={message.docs ?? []}
                                    />
                                )}
                            >
                                {memoizedText}
                            </AnalyzerMarkdown>
                        </div>
                    ) : message.status === 'generating' ? (
                        <LinearLoaderDrawer percentage={percentage} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SummarizationMessage;
