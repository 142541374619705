'use client';
import React, { useContext } from 'react';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import JiminiLink from '@/components/common_V2/JiminiLink';
import BarsReticulaIcon from '@/components/Icons/BarsReticulaIcon';
import PlusIcon from '@/components/Icons/PlusIcon';
import usePostMissionSubmit from '@/hooks/usePostMissionSubmit';

const AnalyzerMainLink = ({
    isAnalyzerActive,
}: {
    isAnalyzerActive: boolean;
}) => {
    const { sideBarCollapsed } = useContext(GeneralContext);

    const activeColor = isAnalyzerActive
        ? 'text-alphaGray12 stroke-alphaGray12'
        : 'text-alphaGray11 stroke-alphaGray11';

    const { resetPromptModule } = usePostMissionSubmit();

    return (
        <div className='relative h-10'>
            <div className='absolute left-0 top-0 z-10 w-full'>
                <JiminiLink
                    href='/'
                    className={`transition-200 group h-10 w-full px-2 ${isAnalyzerActive ? 'bg-alphaGray3' : ''} ${isAnalyzerActive && !sideBarCollapsed ? 'rounded-b-none' : ''}`}
                    textClassName={`font-gilroyTitle text-base ${activeColor} -mb-0.5`}
                    onClick={() => resetPromptModule()}
                    text='Analyzer'
                    iconLeft={
                        <BarsReticulaIcon className={`mr-2 ${activeColor}`} />
                    }
                    iconRight={
                        <PlusIcon
                            className={`scale-0 opacity-0 transition-all duration-150 ease-in-out group-hover:scale-100 group-hover:opacity-100 ${activeColor}`}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default AnalyzerMainLink;
