'use client';
import React from 'react';
import ApryseDrawer from '@/components/Drawers/AprysesDrawer';
import AnswerDrawer from '@/components/Drawers/AnswerDrawer/AnswerDrawer';

const DrawerStack = () => {
    return (
        <>
            <ApryseDrawer />

            <AnswerDrawer />
        </>
    );
};

export default DrawerStack;
