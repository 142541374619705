const materialThemeOptions = {
    typography: {
        fontFamily: [
            'Gilroy', // Replace with your font family
            'Gilroy-Regular',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiPopper: {
            styleOverrides: {
                root: {
                    zIndex: '7003 !important',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: '6003 !important',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'red',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1980,
        },
    },
};

export { materialThemeOptions };
