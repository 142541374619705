'use client';

import React, { useContext } from 'react';
import Link, { LinkProps } from 'next/link';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import StyledTooltip from '@/components/common/Tooltip';

const JiminiLink = ({
    text,
    className,
    textClassName,
    href,
    iconLeft,
    iconRight,
    children,
    disabled,
    noShrink,
    noLink,
    tooltipMessage,
    tooltipClassName,
    ...props
}: {
    text?: string;
    className?: string;
    textClassName?: string;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
    noShrink?: boolean;
    href: string;
    tooltipMessage?: string;
    tooltipClassName?: string;
    noLink?: boolean;
} & Partial<LinkProps>) => {
    const { sideBarCollapsed } = useContext(GeneralContext);
    const Component = noLink ? 'a' : Link;
    return (
        <StyledTooltip
            placement='right'
            // need to use custom render because somehow the MUI tooltip background
            // is not overridable with the classes prop
            title={tooltipMessage}
            className={`${tooltipClassName} rounded-[6px]`}
        >
            {/* this div is only here to make the tooltip appear */}
            <div className='w-full'>
                <Component
                    href={href}
                    className={`relative flex h-10 flex-row items-center justify-between rounded-[6px] ${className} ${
                        disabled ? 'pointer-events-none' : ''
                    }`}
                    prefetch
                    {...props}
                >
                    {text || iconLeft ? (
                        <div className='flex flex-row items-center justify-start'>
                            {iconLeft}
                            {text ? (
                                <span
                                    className={`${
                                        textClassName ||
                                        'font-gilroyTitle text-base text-alphaGray11'
                                    } ${
                                        sideBarCollapsed && !noShrink
                                            ? 'scale-x-0'
                                            : 'scale-x-100'
                                    } origin-left transition-all duration-200 ease-in-out`}
                                >
                                    {text}
                                </span>
                            ) : null}
                        </div>
                    ) : null}
                    {children}
                    {!sideBarCollapsed && !noShrink ? iconRight : null}
                </Component>
            </div>
        </StyledTooltip>
    );
};

export default JiminiLink;
