'use client';
import React, { SetStateAction, useContext, useMemo } from 'react';
import JiminiAvatar from '@/components/Icons/JiminiAvatar';
import DrawerSearchADBToolbar from '@/components/Drawers/AnswerDrawer/SearchADB/Toolbar/DrawerSearchADBToolbar';
import LinearLoaderDrawer from '@/components/NewConversations/Messages/ChatbotMessage/LinearLoaderDrawer';
import usePostProcessMessage from '@/hooks/usePostProcessMessage';
import SearchADBResultList from '@/components/Drawers/AnswerDrawer/SearchADB/Messages/SearchADBResultList';
import { PromptModuleContext } from '@/components/Providers/PromptModuleContext';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import { Source } from '@/types/analyzer';
import { DrawerContext } from '@/components/Providers/DrawerProvider';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';

const SearchADBChatbotMessage = ({
    message,
    sortedBy,
    setSortedBy,
}: {
    message: PublicMessageWithPreviousAndFiles;
    sortedBy: SearchADBSortBy;
    setSortedBy: React.Dispatch<SetStateAction<SearchADBSortBy>>;
}) => {
    const [selectedDocs, setSelectedDocs] = React.useState<string[]>([]);
    const {
        setConfirmedSelectedFiles,
        setCollapsed,
        setSelectedMission,
        setSuggestedMission,
        setSelectedFollowUp,
    } = useContext(PromptModuleContext);
    const { setMissionDrawerOpen, setAnswerDrawer } = useContext(DrawerContext);

    const { percentageComputed } = usePostProcessMessage(message);
    const isGenerating = message.status === 'generating';
    const hasResults = !!message.docs?.length;

    const sources = useMemo(() => {
        if (message.sources) {
            return JSON.parse(message.sources) as Source[];
        } else {
            return [];
        }
    }, [message.sources]);

    const sortDisabled = !sources.length || !message.docs.length;

    const onFollowUp = () => {
        setConfirmedSelectedFiles(
            message.docs.filter((messageDoc) =>
                selectedDocs.includes(messageDoc.id)
            )
        );
        setSelectedFollowUp(null);
        setSuggestedMission(null);
        setSelectedMission('factual');
        setCollapsed(true);
        setMissionDrawerOpen(false);
        setAnswerDrawer(null);
    };

    return (
        <div className='mt-4 flex w-full flex-row items-start justify-start gap-2'>
            <div className='flex items-center justify-center rounded-full'>
                <JiminiAvatar />
            </div>
            <div className='flex flex-col'>
                <span className='text-left font-gilroyBold text-base text-solidGray12'>
                    Jimini
                </span>
                {hasResults && (
                    <div className='flex flex-col gap-4'>
                        <DrawerSearchADBToolbar
                            onFollowUp={onFollowUp}
                            selectedDocsNumber={selectedDocs.length}
                            sortDisabled={sortDisabled}
                            sortedBy={sortedBy}
                            setSortedBy={setSortedBy}
                        />
                        <SearchADBResultList
                            docs={message.docs}
                            sources={sources}
                            selectedDocs={selectedDocs}
                            setSelectedDocs={setSelectedDocs}
                        />
                    </div>
                )}
                {!hasResults && isGenerating && (
                    <div className='text-left'>
                        <LinearLoaderDrawer percentage={percentageComputed} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchADBChatbotMessage;
