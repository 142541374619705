'use client';
import React from 'react';
import MatriceMessage from '@/components/NewConversations/Messages/ChatbotMessage/MatriceMessage';
import usePostProcessMessage from '@/hooks/usePostProcessMessage';
import useSingleMessage from '@/hooks/useSingleMessage';
import {
    AnswerDrawerCloseButton,
    AnswerDrawerMessageWrapper,
    AnswerDrawerTitle,
} from '@/components/Drawers/AnswerDrawer/AnswerDrawerComponents';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';

const AnswerDrawerMatrice = ({
    answerDrawer,
}: {
    answerDrawer: PublicMessageWithPreviousAndFiles;
}) => {
    const { data } = useSingleMessage(answerDrawer!);

    const synchronizedMessage = data || answerDrawer;

    const { percentageComputed } = usePostProcessMessage(synchronizedMessage);

    return (
        <>
            <div className='flex flex-row items-center justify-between px-6 py-5'>
                <AnswerDrawerTitle type_question={'matrice'} />
                <div className='flex flex-row items-center gap-2'>
                    <AnswerDrawerCloseButton />
                </div>
            </div>
            <AnswerDrawerMessageWrapper>
                <MatriceMessage
                    text={synchronizedMessage.text ?? ''}
                    status={synchronizedMessage.status ?? ''}
                    files={synchronizedMessage.docs ?? []}
                    isGenerating={synchronizedMessage.status === 'generating'}
                    percentage={percentageComputed || 1}
                />
            </AnswerDrawerMessageWrapper>
        </>
    );
};

export default AnswerDrawerMatrice;
