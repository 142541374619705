'use client';
import { useEffect, useState } from 'react';

const useScreenFocus = () => {
    const [isFocused, setIsFocused] = useState(true);
    let timeoutId: NodeJS.Timeout | null = null;

    useEffect(() => {
        const onFocus = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
            setIsFocused(true);
        };
        const onBlur = () => {
            // 15 minutes delay before considering the user as inactive
            // allows the users to have a split screen with another app focused
            // and still getting the updates (embedding, last request status)
            timeoutId = setTimeout(() => setIsFocused(false), 15 * 60 * 1000);
        };

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    return isFocused;
};

export default useScreenFocus;
