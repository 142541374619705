'use client';

import JiminiLink from '@/components/common_V2/JiminiLink';
import GearIcon from '@/components/Icons/GearIcon';
import ContextMenu from '@/components/common_V2/ContextMenu';
import React, { useRef } from 'react';
import Avatar from '@/components/ui/Avatar';
import Image from 'next/image';
import JiminiButton from '@/components/common_V2/JiminiButton';
import LogoutIcon from '@/components/Icons/LogoutIcon';

const UserMenuDropdownTrigger = ({
    onClick,
    formattedUserName,
    sideBarCollapsed,
}: {
    onClick: () => void;
    formattedUserName: string;
    sideBarCollapsed: boolean;
}) => {
    return (
        <JiminiButton
            variant='text'
            onClick={onClick}
            text={formattedUserName}
            textSize='text-lg leading-[18px]'
            textClassName={
                'overflow-hidden whitespace-nowrap max-w-[134px] text-ellipsis text-alphaGray12 font-gilroyTitle text-[20px]'
            }
            className={'flex w-full flex-row items-center justify-start'}
            iconLeft={
                <Avatar
                    className={`mr-3 transition-all duration-200 ease-in-out`}
                    author={'user'}
                />
            }
            shrink={sideBarCollapsed}
            iconRight={
                <Image
                    priority
                    src={'/images/V2/tabler/icon_ellipsis_dots.svg'}
                    alt='add icon'
                    width={22}
                    height={22}
                    className={`${
                        sideBarCollapsed ? 'opacity-0' : 'opacity-100'
                    } ml-auto transition-all duration-200 ease-in-out`}
                />
            }
        />
    );
};

const UserMenuDropdown = ({
    logoutDropdownOpen,
    setLogoutDropdownOpen,
    formattedUserName,
    sideBarCollapsed,
    handleClose,
}: {
    logoutDropdownOpen: boolean;
    setLogoutDropdownOpen: (value: boolean) => void;
    formattedUserName: string;
    sideBarCollapsed: boolean;
    handleClose: () => void;
}) => {
    const menuPositionRef = useRef<HTMLDivElement>(null);

    const onTriggerClick = () => {
        setLogoutDropdownOpen(!logoutDropdownOpen);
    };

    return (
        <>
            <UserMenuDropdownTrigger
                onClick={onTriggerClick}
                formattedUserName={formattedUserName}
                sideBarCollapsed={sideBarCollapsed}
            />
            <div ref={menuPositionRef} className='fixed bottom-[176px]'>
                <ContextMenu
                    open={logoutDropdownOpen}
                    handleClose={handleClose}
                    anchorRef={menuPositionRef}
                    anchorReference='anchorEl'
                >
                    <div className='w-[240px]'>
                        <JiminiLink
                            href='/account'
                            text='Compte'
                            className='h-[48px] p-4'
                            textClassName='text-solidBlue12 font-gilroySemiBold text-base'
                            iconLeft={
                                <GearIcon
                                    className={`-mt-0.5 mr-2 stroke-solidBlue12`}
                                />
                            }
                            prefetch={true}
                            noShrink
                        />
                        <JiminiLink
                            href='/api/auth/logout'
                            text='Se déconnecter'
                            className='h-[48px] p-4'
                            textClassName='text-solidBlue12 font-gilroySemiBold text-base'
                            iconLeft={
                                <LogoutIcon className='-mt-0.5 mr-2 stroke-solidBlue12' />
                            }
                            noLink // needed because of an incompatibility between Link prefetch and auth0 logout process
                            noShrink
                        />
                    </div>
                </ContextMenu>
            </div>
        </>
    );
};

export default UserMenuDropdown;
