import { missionSpecificUiMapping } from '@/helpers/missions';
import { MissionsEnum } from '@/types/analyzer';
import { MissionPublicDocType, MissionPublicType } from '@/types/requests';
import { useMemo } from 'react';

const computeMatriceDocs = (docs: MissionPublicDocType[]) => {
    return `Analyse de ${docs.map((doc) => doc.name).join(', ')}`;
};

const computeMagicWandDoc = (docs: MissionPublicDocType[]) => {
    return `Correction de ${docs.map((doc) => doc.name).join(', ')}`;
};

const computeDocumentTranslationDoc = (docs: MissionPublicDocType[]) => {
    return `Traduction de ${docs.map((doc) => doc.name).join(', ')}`;
};

const computeAnonymizationDoc = (docs: MissionPublicDocType[]) => {
    return `Anonymisation de ${docs.map((doc) => doc.name).join(', ')}`;
};

export function useStatusSpecificMissionTrackerLink(
    mission: MissionPublicType
) {
    const MissionTypeSpecificElements =
        missionSpecificUiMapping[mission.type_question as MissionsEnum] ||
        missionSpecificUiMapping['generic'];

    const missionOverviewText = useMemo(() => {
        if (mission.status === 'error') {
            return "Une erreur est survenue lors de l'analyse";
        } else if (mission.status === 'interrupted') {
            return "L'analyse a été interrompue";
        } else if (mission.status === 'generating' && !mission.textForDisplay) {
            return 'Analyse en cours...';
        } else if (mission.type_question === 'matrice') {
            return computeMatriceDocs(mission.docs);
        } else if (mission.type_question === 'magic_wand') {
            return computeMagicWandDoc(mission.docs);
        } else if (mission.type_question === 'document_translation') {
            return computeDocumentTranslationDoc(mission.docs);
        } else if (mission.type_question === 'anonymization') {
            return computeAnonymizationDoc(mission.docs);
        } else {
            return mission.textForDisplay;
        }
    }, [mission.type_question, mission.textForDisplay]);

    return {
        MissionTypeSpecificElements,
        missionOverviewText,
    };
}
