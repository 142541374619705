import React from 'react';

const SortingIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.333}
            d='m2.5 7.5 3.333-3.334m0 0 3.334 3.333M5.833 4.166v11.667M17.5 12.499l-3.333 3.334m0 0-3.334-3.334m3.334 3.334V4.166'
        />
    </svg>
);
export default SortingIcon;
