'use client';
import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import JiminiLink from '@/components/common_V2/JiminiLink';
import SideBarAnalyzerArea from '@/components/SideBar/SideBarAnalyzerArea';
import SideBarLogoNotifArea from '@/components/SideBar/SideBarLogoNotifArea';
import PaperRolledIcon from '@/components/Icons/PaperRolledIcon';
import MagnifyingGlassBars from '@/components/Icons/MagnifyingGlassBars';
import DocumentIcon from '@/components/Icons/DocumentIcon';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import { usePathname } from 'next/navigation';
import AnalyzerMainLink from '@/components/SideBar/AnalyzerMainLink';
import SoonBadge from '@/components/Badges/SoonBadge';
import { FeaturesToToggle } from '../pages/Library/FeatureToggles/FeatureToggles';

const NavGroupTop = () => {
    const [isLegalResearchActive, setIsLegalResearchActive] = useState(false);
    const { sideBarCollapsed } = useContext(GeneralContext);
    const currentPath = usePathname();

    const iconClassnamesCollapsed = `${
        sideBarCollapsed ? 'mr-0' : 'mr-2'
    } transition-all duration-200 ease-in-out`;
    const isAnalyzerActive =
        currentPath?.includes('/new-analyzer') ||
        currentPath === '/conversations' ||
        currentPath === '/';

    const isLibraryActive = currentPath?.includes('/library');

    useEffect(() => {
        setIsLegalResearchActive(
            localStorage.getItem(FeaturesToToggle.LEGAL_RESEARCH) === 'true'
        );
    }, []);

    return (
        <div className='flex w-full flex-col justify-start gap-3.5 px-2.5 pb-10'>
            <SideBarLogoNotifArea />
            <div className='pt-[80px]'>
                <AnalyzerMainLink isAnalyzerActive={isAnalyzerActive} />

                <div
                    className={`duration-400 flex flex-col gap-1.5 transition-all ease-in-out`}
                >
                    <Collapse
                        in={!sideBarCollapsed}
                        orientation='vertical'
                        collapsedSize={0}
                        className='origin-top-left transition-all duration-200 ease-in-out'
                        timeout={{
                            appear: 200,
                            enter: 200,
                            exit: 200,
                        }}
                    >
                        <SideBarAnalyzerArea />
                    </Collapse>
                    <JiminiLink
                        href='/'
                        text='Draft'
                        className={`h-10 cursor-auto px-2 ${sideBarCollapsed ? '' : ''}`}
                        disabled
                        tooltipMessage='Copilote IA à la rédaction, directement intégré dans Microsoft Word.'
                        tooltipClassName=''
                        iconLeft={
                            <PaperRolledIcon
                                className={`${iconClassnamesCollapsed} stroke-alphaGray11`}
                            />
                        }
                        iconRight={<SoonBadge />}
                    />
                    <JiminiLink
                        href='/legal-search'
                        text='Research'
                        className={`h-10 px-2`}
                        tooltipMessage='Posez une question juridique, même complexe et notre lA vous apportera une réponse en quelques secondes, en citant ses sources.'
                        disabled={!isLegalResearchActive}
                        iconLeft={
                            <MagnifyingGlassBars
                                className={`${iconClassnamesCollapsed} stroke-alphaGray11`}
                            />
                        }
                        iconRight={<SoonBadge />}
                    />
                    <JiminiLink
                        href='/library/personal'
                        className={`h-10 px-2 ${
                            isLibraryActive
                                ? 'bg-alphaGray3'
                                : 'bg-transparent hover:bg-alphaGray4'
                        }`}
                        textClassName={`font-gilroyTitle text-base ${isLibraryActive ? 'text-alphaGray12 stroke-alphaGray12' : 'text-alphaGray11 stroke-alphaGray11'}`}
                        text='Documents'
                        iconLeft={
                            <DocumentIcon
                                className={`${iconClassnamesCollapsed} ${isLibraryActive ? 'stroke-alphaGray12' : 'stroke-alphaGray11'} `}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default NavGroupTop;
