'use client';
import React, { useState } from 'react';
import { AnswerDrawerMessageWrapper } from '@/components/Drawers/AnswerDrawer/AnswerDrawerComponents';
import AnswerDrawerSearchADBHeader from '@/components/Drawers/AnswerDrawer/SearchADB/Header/AnswerDrawerSearchADBHeader';
import SearchADBChatbotMessage from '@/components/Drawers/AnswerDrawer/SearchADB/Messages/SearchADBChatbotMessage';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import SearchADBQuestionMessage from '@/components/Drawers/AnswerDrawer/SearchADB/Messages/SearchADBQuestionMessage';
import useSingleMessageSearchADB from '@/hooks/useSingleMessageSearchADB';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';

const AnswerDrawerSearchADB = ({
    answerDrawer,
}: {
    answerDrawer: PublicMessageWithPreviousAndFiles;
}) => {
    const [sortedBy, setSortedBy] = useState<SearchADBSortBy>(
        SearchADBSortBy.Relevance
    );
    const { data } = useSingleMessageSearchADB(answerDrawer, sortedBy);

    const synchronizedMessage = data || answerDrawer;
    return (
        <>
            <AnswerDrawerSearchADBHeader />
            <AnswerDrawerMessageWrapper className='flex flex-col'>
                <SearchADBQuestionMessage message={synchronizedMessage} />
                <SearchADBChatbotMessage
                    message={synchronizedMessage}
                    sortedBy={sortedBy}
                    setSortedBy={setSortedBy}
                />
            </AnswerDrawerMessageWrapper>
        </>
    );
};

export default AnswerDrawerSearchADB;
