import * as React from 'react';

const JiminiLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={92}
        height={36}
        fill='none'
        {...props}
    >
        <path d='M56.637 15.06v11.286h-4.726V15.389c0-2.198-1.136-3.48-3.078-3.48-2.163 0-3.408 1.464-3.408 4.14v10.295h-4.726V15.388c0-2.199-1.136-3.481-3.078-3.481-2.09 0-3.444 1.464-3.444 4.14v10.296H29.45V8.023h4.726v1.94c1.1-1.647 2.785-2.455 5.093-2.455s3.92.88 4.984 2.638c1.209-1.758 3.04-2.638 5.46-2.638 4.213 0 6.925 2.968 6.925 7.549l-.002.003ZM59.02 26.346V8.026h4.727v18.32H59.02ZM81.554 9.526c1.32 1.357 1.98 3.188 1.98 5.57v11.25h-4.727V15.683c0-2.456-1.428-3.775-3.627-3.775-2.456 0-4.03 1.503-4.03 4.543v9.893h-4.726V8.024h4.726v2.051c1.135-1.722 2.968-2.565 5.46-2.565 2.015 0 3.665.66 4.946 2.015l-.002.001ZM85.915 26.346V8.026h4.727v18.32h-4.727ZM26.803 8.049h-4.785v18.295h4.785V8.05ZM21.412 2.93c0-1.575 1.319-2.93 2.895-2.93s2.93 1.355 2.93 2.93c0 1.577-1.318 2.896-2.93 2.896a2.9 2.9 0 0 1-2.895-2.895ZM58.47 2.93c0-1.575 1.319-2.93 2.895-2.93s2.93 1.355 2.93 2.93c0 1.577-1.318 2.896-2.93 2.896A2.9 2.9 0 0 1 58.47 2.93ZM85.367 2.93c0-1.575 1.318-2.93 2.894-2.93s2.931 1.355 2.931 2.93c0 1.577-1.319 2.896-2.93 2.896a2.9 2.9 0 0 1-2.895-2.895ZM14.005 2.93C14.005 1.356 15.324 0 16.9 0s2.93 1.355 2.93 2.93c0 1.577-1.318 2.896-2.93 2.896a2.9 2.9 0 0 1-2.895-2.895ZM9.61 36c-2.54 0-4.946-1.008-6.774-2.837C1.008 31.334 0 28.928 0 26.389c0-5.26 4.402-9.7 9.61-9.7h2.488v4.785H9.61c-2.57 0-4.826 2.297-4.826 4.916 0 2.62 2.21 4.826 4.826 4.826 2.616 0 4.916-2.21 4.916-4.826V8.05h4.785V26.39c0 5.3-4.35 9.61-9.7 9.61H9.61Z' />
    </svg>
);
export default JiminiLogo;
