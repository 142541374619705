'use client';

import { GeneralContext } from '@/components/Providers/GeneralContext';
import Toggle from '@/components/ui/Toggle';
import useJiminiUser from '@/hooks/useJiminiUser';
import { useRouter } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';

export enum FeaturesToToggle {
    LEGAL_RESEARCH = 'legalResearch',
}

function FeatureToggles() {
    const [isLegalResearchActive, setIsLegalResearchActive] = useState(false);
    const { user_org } = useJiminiUser();
    const router = useRouter();
    //TODO: we should not have to use sideBarCollapsed for styling
    const { sideBarCollapsed } = useContext(GeneralContext);

    useEffect(() => {
        setIsLegalResearchActive(
            localStorage.getItem(FeaturesToToggle.LEGAL_RESEARCH) === 'true'
        );
    }, []);

    if (user_org === null) {
        return (
            <h1 className='font-gilroyTitle text-solidBlue12'>...Loading...</h1>
        );
    }
    if (user_org.name !== 'jimini') {
        router.replace('/');
    }

    const handleLegalResearchToggleChange = () => {
        const newIsLegalResearchActive = !isLegalResearchActive;
        localStorage.setItem(
            FeaturesToToggle.LEGAL_RESEARCH,
            JSON.stringify(!isLegalResearchActive)
        );
        setIsLegalResearchActive(newIsLegalResearchActive);
    };

    return (
        user_org.name === 'jimini' && (
            <div
                className={`relative right-0 flex h-full flex-col gap-6 pr-6 ${
                    sideBarCollapsed
                        ? 'tablet:left-[60px] tablet:min-w-[calc(100vw-60px)] tablet:max-w-[calc(100vw-60px)]'
                        : 'tablet:left-[240px] tablet:min-w-[calc(100vw-240px)] tablet:max-w-[calc(100vw-240px)]'
                } transition-all duration-200 ease-in-out`}
            >
                <h1 className='font-gilroyTitle text-solidBlue12'>
                    Feature toggles
                </h1>
                <p className='font-gilroyMedium text-solidBlue12'>
                    Activer une fonctionnalité te permettra de la voir sur
                    l‘environnement sur lequel tu es sur ton navigateur, ça ne
                    s‘applique pas aux autres environnements ni aux autres
                    utilisateurs.
                </p>
                <p className='font-gilroyMedium text-solidBlue12'>
                    <strong>ATTENTION</strong> Pour ne pas générer d‘effets
                    bizarres n‘utilise pas cette page en prod.
                </p>
                <p className='font-gilroyMedium text-solidBlue12'>
                    Si les modifications que tu attends ne sont pas apparues
                    pense à rafraîchir la page !
                </p>
                <div className='flex flex-col p-6'>
                    <Toggle
                        label='Recherche juridique'
                        checked={isLegalResearchActive}
                        onChange={handleLegalResearchToggleChange}
                    />
                </div>
            </div>
        )
    );
}

export default FeatureToggles;
