'use client';
import MissionTrackerButton from '@/components/MissionTracker/MissionTrackerButton';
import MissionTrackerHeader from '@/components/MissionTracker/MissionTrackerHeader';
import MissionTrackerLink from '@/components/MissionTracker/MissionTrackerLink';
import { SidebarContext } from '@/components/Providers/GlobalProviders';
import ContextMenu from '@/components/common_V2/ContextMenu';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import useMissionTracker from '@/hooks/useMissionTracker';
import apiAnalyzer from '@/requests/apis/analyzer';
import { useContext, useRef } from 'react';

const MissionTracker = () => {
    const menuPositionRef = useRef<HTMLDivElement>(null);

    const { setMissionTrackingOpen, missionTrackingOpen } =
        useContext(SidebarContext);

    const { data, error } = useMissionTracker();

    const onTriggerClick = () => {
        setMissionTrackingOpen(!missionTrackingOpen);
    };
    const handleClose = () => {
        setMissionTrackingOpen(false);
    };

    const hasUnreadMissions = data?.length
        ? data?.some((mission) => !mission.read)
        : false;

    const { refetchMissionTracking } = useJiminiQuery();

    const setAllMissionSeen = async () => {
        if (data?.length) {
            await apiAnalyzer.setMissionsSeen({
                message_ids: data?.map((mission) => mission.id),
            });
            refetchMissionTracking().then();
        }
    };
    const LONG_MISSIONS = [
        'summarization',
        'matrice',
        'magic_wand',
        'document_translation',
        'search_adb',
        'anonymization',
    ];

    return (
        <div className='pr-2'>
            <MissionTrackerButton
                onTriggerClick={onTriggerClick}
                hasUnreadMissions={hasUnreadMissions}
            />
            <div ref={menuPositionRef} className='fixed top-[68px]'>
                <ContextMenu
                    open={missionTrackingOpen}
                    handleClose={handleClose}
                    anchorRef={menuPositionRef}
                    anchorReference='anchorEl'
                >
                    <div className='w-[512px]'>
                        <MissionTrackerHeader
                            setAllMissionSeen={setAllMissionSeen}
                        />
                        <div className='flex max-h-[60vh] w-full flex-col overflow-y-auto overflow-x-hidden'>
                            {data?.map((mission, index) => {
                                if (
                                    LONG_MISSIONS.includes(
                                        mission.type_question
                                    )
                                ) {
                                    return (
                                        <MissionTrackerLink
                                            key={index}
                                            index={index}
                                            mission={mission}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </ContextMenu>
            </div>
        </div>
    );
};

export default MissionTracker;
