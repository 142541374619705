'use client';
import React from 'react';
import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { WrapperComponentType } from '@/types/general';

const Tooltip = ({
    children,
    tooltipClasses,
    title,
    titleType = 'string',
    titleClasName,
    ...props
}: TooltipProps &
    WrapperComponentType & {
        tooltipClasses?: string;
        titleType?: 'string' | 'component';
        titleClasName?: string;
    }) => {
    return (
        <>
            <MUITooltip
                {...props}
                classes={{
                    popper: `text-grey1 ${!title ? 'hidden' : ''} boxShadow-mui-1`,
                    tooltip: `${tooltipClasses || ''} p-0`,
                }}
                title={
                    titleType === 'component' ? (
                        title
                    ) : (
                        <div
                            className={`${titleClasName || 'w-[256px]'} rounded-[6px] bg-alphaGray12 px-2 py-2`}
                        >
                            <span className='font-gilroyMedium text-sm text-white'>
                                {title}
                            </span>
                        </div>
                    )
                }
            >
                {children}
            </MUITooltip>
        </>
    );
};
export default Tooltip;
