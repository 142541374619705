'use client';

import moment from 'moment';
import 'moment/locale/fr';
export const DisplayDate = ({
    createdAt,
    matrixFormatting,
}: {
    createdAt: Date | string;
    matrixFormatting?: boolean;
}) => {
    moment.locale('fr');
    const created = moment(createdAt);
    const today = moment();
    const isMoreThanOneDayOlder = created.isBefore(today.subtract(1, 'day'));
    const lessThanOneDayOlderFormatted = matrixFormatting
        ? `Créé ${created.fromNow()}`
        : created.fromNow();
    // grammar condition: 1 day or less give something like "il y a 5 minutes"

    const isMoreThanOneDayOlderFormatted = matrixFormatting
        ? `Créé le ${created.format('DD MMMM YYYY')}`
        : created.format('DD MMMM YYYY');
    // grammar condition: more than 1 day give something like "12 octobre 2021"
    return (
        <>
            {!isMoreThanOneDayOlder
                ? lessThanOneDayOlderFormatted
                : isMoreThanOneDayOlderFormatted}
        </>
    );
};
