'use client';
import React from 'react';
import { Alert } from '@mui/material';

const PromptError = ({ text }: { text?: string }) => {
    return (
        <Alert
            severity={'error'}
            variant='filled'
            className='mb-2 mt-2 flex h-[58px] flex-row items-center bg-redError2 pt-0 font-gilroyTitle text-base'
            sx={{
                paddingBottom: '0',
                '& .MuiAlert-action': {
                    paddingTop: '0px',
                },
                '& .MuiAlert-icon': {},
                '& .MuiAlert-message': {
                    marginTop: '4px',
                },
            }}
        >
            {text || "Une erreur est survenue lors de l'envoi de la requête"}
        </Alert>
    );
};

export default PromptError;
