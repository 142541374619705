'use client';

import React from 'react';
import SortingIcon from '@/components/Icons/SortingIcon';
import ArrowSquareBottom from '@/components/Icons/ArrowSquareBottom';

const SortPickerButtonSearchADB = ({
    isOpened,
    sortedByLabel,
    disabled,
}: {
    isOpened: boolean;
    sortedByLabel: string;
    disabled?: boolean;
}) => {
    return (
        <button
            disabled={disabled}
            className='flex items-center gap-1 bg-alphaBlue3 px-4 py-2 font-gilroySemiBold text-solidBlue12'
        >
            <SortingIcon
                width={16}
                height={16}
                className='mr-1 stroke-solidBlue12'
            />
            <span className='-mb-0.5'>{`Trier par : ${sortedByLabel}`}</span>
            <ArrowSquareBottom
                width={22}
                height={22}
                viewBox='0 0 24 24'
                className={`${isOpened ? '-rotate-180' : 'rotate-0'} transition-200 stroke-solidBlue12`}
            />
        </button>
    );
};

export default SortPickerButtonSearchADB;
