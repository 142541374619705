'use client';
import React, { useContext, useMemo } from 'react';
import JiminiLink from '@/components/common_V2/JiminiLink';
import AnalyzerSidebarStateIndicators from '@/components/SideBar/AnalyzerSidebarStateIndicators';
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import useResponsive from '@/hooks/useResponsive';
import { usePathname } from 'next/navigation';
import { Collapse } from '@mui/material';
import AnalyzerCard from '@/components/SideBar/AnalyzerCard';
import useSideBarConversations from '@/hooks/useSideBarConversations';

const SideBarAnalyzerArea = () => {
    const { data, isLoading, error, refetch } = useSideBarConversations();
    const { sideBarCollapsed } = useContext(GeneralContext);
    const { screenHeight } = useResponsive();
    const currentPath = usePathname();

    const conversationsNumber = useMemo(() => {
        if (data?.conversations?.length) {
            if (screenHeight > 1000) {
                return 5;
            } else if (screenHeight > 850) {
                return 4;
            } else {
                return 3;
            }
        } else {
            return 0;
        }
    }, [screenHeight, data?.conversations?.length]);

    const latestConversations = useMemo(() => {
        if (data?.conversations?.length) {
            return data.conversations.slice(0, conversationsNumber);
        } else {
            return [];
        }
    }, [data, conversationsNumber]);

    const analyzerActive =
        currentPath.includes('/new-analyzer') ||
        currentPath === '/conversations' ||
        currentPath === '/';

    const transitionClassName = useMemo(() => {
        return sideBarCollapsed ? 'scale-0 ' : 'scale-100';
    }, [sideBarCollapsed]);
    return (
        <div
            className={`flex w-full flex-1 flex-col items-start justify-center rounded-b-[6px] ${transitionClassName} ${analyzerActive ? 'bg-alphaGray3' : 'bg-transparent'} ${analyzerActive && !sideBarCollapsed ? 'mb-1' : ''} transition-200 origin-top-left`}
        >
            <Collapse
                in={analyzerActive}
                orientation='vertical'
                collapsedSize={0}
                aria-setsize={latestConversations.length}
                className='w-full origin-top transition-all duration-200 ease-in-out'
                timeout={{
                    appear: 200,
                    enter: 200,
                    exit: 200,
                }}
            >
                {latestConversations.length ? (
                    latestConversations.map((conversation, index) => (
                        <AnalyzerCard
                            isActive={currentPath.includes(conversation.id)}
                            key={index}
                            conversation={conversation}
                        />
                    ))
                ) : (
                    <AnalyzerSidebarStateIndicators
                        isLoading={isLoading}
                        error={error}
                        conversationNumber={data?.conversations?.length ?? 0}
                    />
                )}
                <JiminiLink
                    href={'/conversations'}
                    text='Voir toutes'
                    textClassName='font-gilroyTitle text-sm text-alphaGray11'
                    className='transition-100 h-10 min-w-full justify-between rounded-b-[6px] rounded-t-none border-t border-t-alphaGray3 px-2 hover:bg-alphaGray4'
                    iconRight={
                        <ArrowRightIcon className='stroke-alphaGray12' />
                    }
                />
            </Collapse>
        </div>
    );
};

export default SideBarAnalyzerArea;
