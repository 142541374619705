'use client';
import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from 'react';

type UpdateModalContextType = {
    initialValue: string;
    updateFieldModalOpen: boolean;
    field_id: string;
    queryKey: string;
    fieldType: 'conversation_name' | 'file_name' | 'new_file_name';
};
type MoveModalContextType = {
    moveModalOpen: boolean;
    targetLibrary: string;
    queryKey: string;
    idsToMove: string[];
};
type DeleteModalContextType = {
    deleteConversationModalOpen: boolean;
    initialValue: string;
    fieldType: 'conversation' | 'conversation_threads_page' | 'file';
    queryKey: string;
    idToDelete: string;
    idsToDelete: string[];
};

type GeneralContextType = {
    updateModal?: UpdateModalContextType;
    setUpdateModal: Dispatch<SetStateAction<UpdateModalContextType>>;
    deleteModal?: DeleteModalContextType;
    setDeleteModal: Dispatch<SetStateAction<DeleteModalContextType>>;
    sideBarCollapsed: boolean;
    setSideBarCollapsed: Dispatch<SetStateAction<boolean>>;
};
export const GeneralContext = createContext<GeneralContextType>({
    updateModal: {
        updateFieldModalOpen: false,
        initialValue: '',
        field_id: '',
        queryKey: '',
        fieldType: 'conversation_name',
    },
    setUpdateModal: () => {},
    deleteModal: {
        deleteConversationModalOpen: false,
        initialValue: '',
        idToDelete: '',
        queryKey: '',
        idsToDelete: [],
        fieldType: 'conversation',
    },
    setDeleteModal: () => {},
    sideBarCollapsed: false,
    setSideBarCollapsed: () => {},
});

const GeneralProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [updateModal, setUpdateModal] = useState<UpdateModalContextType>({
        updateFieldModalOpen: false,
        initialValue: '',
        field_id: '',
        queryKey: '',
        fieldType: 'conversation_name',
    });
    const [sideBarCollapsed, setSideBarCollapsed] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<DeleteModalContextType>({
        deleteConversationModalOpen: false,
        initialValue: 'Jimini Analyzer',
        fieldType: 'conversation',
        queryKey: '',
        idToDelete: '',
        idsToDelete: [],
    });

    return (
        <GeneralContext.Provider
            value={{
                updateModal,
                setUpdateModal,
                deleteModal,
                setDeleteModal,
                sideBarCollapsed,
                setSideBarCollapsed,
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
};
export default GeneralProvider;
