import { useContext } from 'react';
import { PromptModuleContext } from '@/components/Providers/PromptModuleContext';

const usePostMissionSubmit = () => {
    const {
        setConfirmedSelectedFiles,
        setCollapsed,
        setSelectedMission,
        setSelectedFollowUp,
        setSelectedLanguage,
    } = useContext(PromptModuleContext);

    const resetPromptModule = () => {
        setConfirmedSelectedFiles([]);
        setSelectedFollowUp(null);
        setSelectedMission('generic');
        setSelectedLanguage('english');
        setCollapsed(false);
    };
    return {
        resetPromptModule,
    };
};

export default usePostMissionSubmit;
