'use client';
import React, { useEffect } from 'react';
import useResponsive from '@/hooks/useResponsive';

const MobileWarningModal = () => {
    // The challenge : force the screen to be wide on mobile so that prompt module is usable
    // and still have a readable modal on mobile.
    const [isOpen, setIsOpen] = React.useState<null | boolean>(null);

    useEffect(() => {}, []);

    const onClose = () => {
        setIsOpen(false);
    };
    const { isMobile } = useResponsive();

    useEffect(() => {
        if (isOpen === null && isMobile) {
            // Open the modal once if the user is on mobile.
            setIsOpen(true);
        } else if (isOpen === true && !isMobile) {
            // Reset to null if the user is not on mobile anymore.
            // This allow the modal to reopen on mobile until the user close it.
            setIsOpen(null);
        }
    }, [isOpen, isMobile]);

    return (
        isOpen && (
            <dialog
                className='fixed z-[100020] flex h-full w-full items-center justify-center px-4'
                onCancel={onClose}
            >
                <div className='relative z-[100030] flex h-fit flex-col justify-between rounded-xl bg-white p-10 text-left'>
                    <div className='flex flex-row items-center justify-between'>
                        <h1 className='text-left font-gilroySemiBold text-[2.3rem] text-alphaGray12'>
                            Version mobile
                        </h1>
                    </div>

                    <p className='text-[1.8rem]'>
                        {
                            "La version mobile de Jimini est en cours de développement. Pour une expérience optimale, nous vous recommandons d'utiliser un ordinateur."
                        }
                    </p>
                    <div className='flex justify-end pt-6'>
                        <button
                            className='rounded-[6px] bg-alphaBlue3 px-3 py-2 font-gilroySemiBold text-[1.7rem] text-solidBlue12'
                            onClick={() => onClose()}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </dialog>
        )
    );
};

export default MobileWarningModal;
