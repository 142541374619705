'use client';
import { Alert } from '@mui/material';
import React from 'react';

const ModalErrorSnackbar = ({ error }: { error: string }) => {
    return (
        <Alert
            severity={'error'}
            variant='filled'
            className='flex h-[40px] flex-row items-center bg-redError2 pt-0 font-gilroyTitle text-base'
            sx={{
                paddingBottom: '0',
                '& .MuiAlert-action': {
                    paddingTop: '0px',
                },
                '& .MuiAlert-icon': {},
                '& .MuiAlert-message': {
                    marginTop: '4px',
                },
            }}
        >
            {error}
        </Alert>
    );
};

export default ModalErrorSnackbar;
