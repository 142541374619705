'use client';

import Intercom from '@intercom/messenger-js-sdk';
import { useUser } from '@auth0/nextjs-auth0/client';

export default function IntercomProvider() {
    const user = useUser();
    Intercom({
        app_id: 'q6mvniso',
        user_id: user.user?.sub ?? undefined,
    });

    return null;
}
