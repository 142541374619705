'use client';
import useScreenFocus from '@/hooks/useScreenFocus';
import { MessageFromSocket, WebsocketConfig } from '@/types/general';
import Alert from '@mui/material/Alert/Alert';
import Snackbar from '@mui/material/Snackbar/Snackbar';
import { isServer, useQueryClient } from '@tanstack/react-query';
import router from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import { usePathname } from 'next/navigation';
import { PublicMessage } from '@/types/requests';
import apiSocketsAuth from '@/requests/apis/socketsAuth';

const WebSocketConnection = () => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [config, setConfig] = useState<WebsocketConfig | null>(null);
    const [retryNumber, setRetryNumber] = useState<number>(0);
    const [socketError, setSocketError] = useState<boolean | string>(false);
    const isFocus = useScreenFocus();
    const queryClient = useQueryClient();
    const {
        refetchMessageById,
        refetchConversations,
        refetchConversationById,
    } = useJiminiQuery();
    const pathname = usePathname();

    const currentConversationId = useRef<string>('');
    // using ref to avoid having to put it in dependency of useCallback
    // which would reconnect the socket every time we change conversation
    useEffect(() => {
        currentConversationId.current = pathname?.split('/')?.pop() ?? '';
    }, [pathname]);

    useEffect(() => {
        return () => {
            socket?.close();
        };
    }, []);
    useEffect(() => {
        if (isFocus) {
            setRetryNumber(0);
        }
    }, [isFocus]);
    useEffect(() => {
        console.log(socket, socket?.readyState, isFocus, retryNumber);
        if (
            socket &&
            socket?.readyState === socket?.CLOSED &&
            isFocus &&
            retryNumber <= 10 &&
            !isServer
        ) {
            initiateSecureConnexion();
        }
    }, [isFocus, socket?.readyState]);

    const initiateSecureConnexion = async () => {
        const config = await apiSocketsAuth.initiateSocketAuth();
        if (config?.token) {
            setConfig(config);
            connect(config);
        } else {
            setRetryNumber((prev) => prev + 1);
            console.log('socket secure connexion failed', config);
        }
    };

    useEffect(() => {
        initiateSecureConnexion();
    }, []);

    const connect = useCallback(
        (config: WebsocketConfig) => {
            if (socket) {
                socket.close();
            }
            try {
                const openSocket = new WebSocket(
                    `${config.url}?token=${config.token}`
                );
                setSocket(openSocket);

                openSocket.onclose = function (e) {
                    console.log('socket closed with code:', e.code);
                    if (e.code !== 1000 && e.code !== 1001) {
                        setRetryNumber((prev) => prev + 1);
                    }

                    if (e.code === 1008) {
                        // || e.code === 1006) {
                        //setAnswerDisabled(true);
                        router.replace('/api/auth/logout');
                    }
                };

                openSocket.onopen = function (e) {
                    console.log('Socket opened');
                };

                openSocket.onmessage = onMessageSocket;

                openSocket.onerror = function (err) {
                    console.error(
                        'Socket encountered error: ',
                        err,
                        'Closing socket'
                    );
                    setRetryNumber((prev) => prev + 1);
                    openSocket.close();
                    // setSocket(null)
                    setSocketError(JSON.stringify(err));
                };
            } catch (err) {
                console.error('Encountered error: ', err, 'Closing socket');
                // setSocket(null)
                setSocketError(JSON.stringify(err));
            }
        },
        [config]
    );
    const onMessageSocket = useCallback(async (e: any) => {
        let data: MessageFromSocket;
        try {
            data = JSON.parse(e.data) as MessageFromSocket;
        } catch (error) {
            console.error('Invalid message', e.data, error);
            return;
        }
        if (data?.error) {
            console.error(
                `Socket error: ${data?.error}: ${
                    data?.debug ? JSON.stringify(data.debug) : ''
                }`
            );
            setSocketError(`${data?.error}: ${data?.debug}`);
        } else if (
            data?.action === 'error_message' ||
            data?.action === 'refresh_message' ||
            data?.action === 'end_message'
        ) {
            refetchMessageById(data.message_id);
            if (currentConversationId.current) {
                refetchConversationById(currentConversationId.current);
            }
            refetchConversations();
        } else if (data?.action === 'completing_message') {
            queryClient.setQueryData(
                ['message', data.message?.id],
                (oldData: PublicMessage) => {
                    // just in case local storage has an error
                    if (data.message?.text && oldData?.id) {
                        return {
                            ...oldData,
                            ...{ ...oldData, text: data.message.text },
                        };
                    } else if (data?.message) {
                        refetchMessageById(data.message.id);
                    } else {
                        refetchConversations();
                    }
                }
            );
        } else {
            console.error('Unknown action', data);
        }
    }, []);

    return (
        <>
            <Snackbar
                open={false}
                // open={!!socketError}
                autoHideDuration={6000}
                onClose={() => {}}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => {}} severity={'error'}>
                    Socket error: ${socketError}
                </Alert>
            </Snackbar>
        </>
    );
};

export default WebSocketConnection;
