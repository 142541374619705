'use client';

import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';

const InfiniteCircularLoader = ({
    ...props
}: Partial<CircularProgressProps>) => {
    return (
        <CircularProgress
            size={12}
            thickness={4}
            classes={{
                circle: 'text-solidBlue11 h-4 w-4',
            }}
            {...props}
        />
    );
};
export default InfiniteCircularLoader;
