'use client';

import React from 'react';
import { UserAvatarWithoutPicture } from '@/components/ui/UserAvatar';
import { useUser } from '@auth0/nextjs-auth0/client';
import Skeleton from '@mui/material/Skeleton';
import JiminiAvatar from '@/components/Icons/JiminiAvatar';

const Avatar = ({
    author,
    className,
    fileCreator,
}: {
    author?: string;
    className?: string;
    fileCreator?: {
        given_name: string;
        family_name: string;
        picture: string;
    };
}) => {
    const isChatbot = author === 'chatbot';
    const user = useUser();
    const givenName = user?.user?.given_name ?? '';
    return (
        <div
            className={`${className} flex items-center justify-center rounded-full`}
        >
            {(fileCreator && givenName) || (!isChatbot && givenName) ? (
                <UserAvatarWithoutPicture nameLetter={givenName as string} />
            ) : isChatbot ? (
                <JiminiAvatar />
            ) : (
                <Skeleton
                    variant={'circular'}
                    height={28}
                    width={28}
                    className='mr-0.5 min-w-[28px]'
                />
            )}
        </div>
    );
};

export default Avatar;
