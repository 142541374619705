'use client';
import React, { useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import InfiniteCircularLoader from '@/components/Loader/InfiniteCircularLoader';
import Link from 'next/link';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import { PublicConversation } from '@/types/requests';
import { DisplayDate } from '../ui/DisplayDate';

const AnalyzerCard = ({
    conversation,
    isActive,
}: {
    conversation: PublicConversation;
    isActive: boolean;
}) => {
    const [numberOfLines, setNumberOfLines] = React.useState<number>(2);
    const spanRef = React.useRef<HTMLSpanElement>(null);
    const { sideBarCollapsed } = useContext(GeneralContext);

    useEffect(() => {
        setNumberOfLines(0);
        // this one is tricky, 2 requirement :
        // 1. a smooth animation "reduire le menu" for which we need
        // a fixed height (otherwise if we dont use fixed height,
        // the menu elements below "giggle" when the menu is reduced)
        // 2. a varying height based on the number of lines taken by the title
        // between 1 and 2

        // In order to make it possible. we need a first render without
        // defined height (cf numberOfLinesClassName, first condition),
        // then we measure the height taken by the span, and then we constrain
        // the height of the Link to 1 or 2 lines preset (72 - 88) in a second render.

        // In order to force a first render, when the title of the first conversation
        // change from 1 line to 2 on new conversation (origin of the 1876 bug),
        // we need a separate useEffect because React "group"
        // the state updates in a single effect cycle to prevent multiple renders
    }, [conversation.title]);
    useLayoutEffect(() => {
        const handleResize = () => {
            if (sideBarCollapsed) return;
            if (!spanRef.current) return;

            const lines = spanRef.current.clientHeight / 20;
            setNumberOfLines(lines <= 1 ? 1 : 2);
        };
        if (spanRef.current) {
            handleResize();

            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(spanRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [conversation.title, sideBarCollapsed]);

    const numberOfLinesClassName = useMemo(() => {
        if (numberOfLines === 0) {
            return 'h-fit max-h-fit';
        } else if (numberOfLines === 1) {
            return 'h-[72px] max-h-[72px]';
        } else {
            return 'h-[90px] max-h-[90px]';
        }
    }, [numberOfLines]);

    return (
        <Link
            href={`/new-analyzer/${conversation.id}`}
            className={`duration-50 flex overflow-hidden ${numberOfLinesClassName} w-full cursor-pointer flex-col justify-between border-t border-t-alphaGray3 ${isActive ? 'bg-alphaGray5' : 'hover:bg-alphaGray4'} px-2 py-3.5 transition-all ease-in-out`}
        >
            <span
                ref={spanRef}
                className='mb-2 line-clamp-2 text-left font-gilroySemiBold text-sm text-alphaGray11'
            >
                {conversation.title}
            </span>
            {conversation.isGenerating ? (
                <div className='flex flex-row items-center justify-between'>
                    <span className='text-left font-gilroySemiBold text-xs text-alphaGray9'>
                        {'En traitement'}
                    </span>
                    <InfiniteCircularLoader
                        classes={{
                            circle: 'text-[#001F497F] h-4 w-4',
                        }}
                    />
                </div>
            ) : null}
            {!conversation.isGenerating ? (
                <div>
                    <span className='flex justify-self-start text-left font-gilroySemiBold text-xs text-alphaGray9'>
                        <DisplayDate
                            createdAt={conversation?.created_at ?? '-'}
                        />
                    </span>
                </div>
            ) : null}
        </Link>
    );
};
export default AnalyzerCard;
