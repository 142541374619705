import * as React from 'react';

const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        viewBox='0 0 21 20'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M12.167 6.667V5A1.667 1.667 0 0 0 10.5 3.333H4.667A1.667 1.667 0 0 0 3 5v10a1.667 1.667 0 0 0 1.667 1.667H10.5A1.666 1.666 0 0 0 12.167 15v-1.667M8 10h10m0 0-2.5-2.5M18 10l-2.5 2.5'
        />
    </svg>
);
export default LogoutIcon;
