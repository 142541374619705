import ArrowRightIcon from '@/components/Icons/ArrowRightIcon';
import LifeBuoyIcon from '@/components/Icons/LifeBuoyIcon';

type LinkToHelpCenterProps = {
    isSideBarCollapsed: boolean;
};

function LinkToHelpCenter({ isSideBarCollapsed }: LinkToHelpCenterProps) {
    return (
        <a
            href='https://intercom.help/odysai/en/'
            target='_blank'
            rel='noreferrer'
            className='flex items-center px-2 py-1.5 font-gilroyTitle text-base text-alphaGray11'
        >
            <LifeBuoyIcon
                className='stroke-alphaGray11'
                width={24}
                height={24}
            />
            {!isSideBarCollapsed && (
                <>
                    <span className='ml-[19px]'>Aide & Ressources</span>
                    <ArrowRightIcon className='ml-2 -rotate-45 stroke-alphaGray8' />
                </>
            )}
        </a>
    );
}

export default LinkToHelpCenter;
