import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
    PublicMessagePreviewWithPreviousAndFiles,
    PublicMessageWithPreviousAndFiles,
} from '@/types/requests';
import apiChatMessage from '@/requests/apis/chatMessage';

const useSingleMessage = (
    message: PublicMessagePreviewWithPreviousAndFiles
) => {
    const queryClient = useQueryClient();
    const cachedMessage = (queryClient.getQueryData([
        'message',
        message.id,
    ]) as PublicMessageWithPreviousAndFiles) ?? {
        ...message,
        text: '',
    };

    const { data } = useQuery(
        {
            queryKey: ['message', cachedMessage.id],
            queryFn: () => apiChatMessage.getMessageById(cachedMessage.id),
            gcTime: 86400,
            staleTime:
                cachedMessage.status === 'saved' &&
                cachedMessage.text &&
                cachedMessage.text.length !== 0
                    ? 86400
                    : 0,
            initialData: cachedMessage,
            refetchInterval: (query) => {
                const messageData = query.state?.data;
                const isMatrixGenerating =
                    messageData?.type_question === 'matrice' &&
                    messageData?.status === 'generating';
                if (isMatrixGenerating) {
                    return 3000;
                } else {
                    return false;
                }
            },
        },
        queryClient
    );
    return {
        data,
    };
};

export default useSingleMessage;
