import { WebsocketConfig } from '@/types/general';
import { callApi } from '../callApi';

const apiSocketsAuth = {
    initiateSocketAuth: async () => {
        return callApi(`/api/websocket/config`, {
            method: 'GET',
        }).then((res) => res.json() as unknown as WebsocketConfig);
    },
};

export default apiSocketsAuth;
