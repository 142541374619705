'use client';
import React, { useState, useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { WrapperComponentType } from '@/types/general';
import HamburgerMenuButton from '@/components/common/HamburgerMenuButton';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import NavGroupBottom from '@/components/navigationPanel/NavGroupBottom';
import NavGroupTop from '@/components/navigationPanel/NavGroupTop';
import dynamic from 'next/dynamic';

const NoSSRMobileMenu = dynamic(() => import('../navigationPanel/MobileMenu'), {
    ssr: false,
});

const PanelTop = ({ children }: WrapperComponentType) => (
    <div
        className={`h-18 fixed z-50 flex w-full items-center justify-between bg-background1 p-4 mobile:flex tablet:hidden desktop:hidden`}
    >
        {children}
    </div>
);

const MobileHome = () => (
    <Link href='/' prefetch={true}>
        <Image
            priority
            src='/images/JiminiBlue.svg'
            alt='Jimini logo'
            width='80'
            height='80'
        />
    </Link>
);

const NavigationPanel = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { sideBarCollapsed } = useContext(GeneralContext);
    return (
        <>
            <div
                className={`fixed left-0 top-0 z-[56] flex h-screen flex-col items-start justify-between overflow-hidden bg-solidGray2 mobile:hidden desktop:overflow-y-auto ${
                    sideBarCollapsed ? 'tablet:w-[60px]' : 'tablet:w-[244px]'
                } origin-top-left transition-all duration-200 ease-in-out`}
            >
                <NavGroupTop />
                <NavGroupBottom />
            </div>
            <PanelTop>
                <MobileHome />
                <HamburgerMenuButton
                    open={mobileMenuOpen}
                    setIsOpen={setMobileMenuOpen}
                />
            </PanelTop>

            <NoSSRMobileMenu
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
            />
        </>
    );
};

export default NavigationPanel;
