const searchSpacesDefault = [
    {
        name: 'Tous',
        value: 'all',
        selected: false,
    },
    {
        name: 'Loi',
        value: 'LOI',
        selected: false,
    },
    {
        name: 'Décret',
        value: 'DECRET',
        selected: false,
    },
    {
        name: 'Arrêté',
        value: 'ARRETE',
        selected: false,
    },
    {
        name: 'Code',
        value: 'CODE',
        selected: false,
    },
    {
        name: 'Ordonnance',
        value: 'ORDONNANCE',
        selected: false,
    },
    {
        name: 'Jurisprudence Judiciaire',
        value: 'JURI_JUDI',
        selected: false,
    },
    {
        name: 'Jurisprudence Administrative',
        value: 'JURI_ADMIN',
        selected: false,
    },
    {
        name: 'Jurisprudence Constitutionnelle',
        value: 'JURI_CONST',
        selected: false,
    },
];
export { searchSpacesDefault };
