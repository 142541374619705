import * as React from 'react';

const Bell = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.667}
            d='M7.5 14.167V15a2.5 2.5 0 0 0 5 0v-.833m-4.167-10a1.667 1.667 0 0 1 3.334 0 5.833 5.833 0 0 1 3.333 5v2.5a3.333 3.333 0 0 0 1.667 2.5H3.333A3.334 3.334 0 0 0 5 11.667v-2.5a5.833 5.833 0 0 1 3.333-5Z'
        />
    </svg>
);
export default Bell;
