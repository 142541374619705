'use client';

import React, { useMemo } from 'react';
import { AnswerMatrixRaw } from '@/types/analyzer';
import MatrixAnswerTable from '@/components/Chat/MatrixAnswerTable';
import PromptError from '@/components/PromptModule/PromptError';
import LinearLoaderDrawer from '@/components/NewConversations/Messages/ChatbotMessage/LinearLoaderDrawer';

const MatriceMessage = ({
    text,
    status,
    files,
    isGenerating,
    percentage,
}: {
    text: string;
    status: string;
    files: any[];
    isGenerating: boolean;
    percentage: number;
}) => {
    const textParsed = useMemo(() => {
        try {
            return JSON.parse(text) as AnswerMatrixRaw[];
        } catch (e) {
            return [];
        }
    }, [text]);

    if (!textParsed?.length) {
        return (
            <PromptError text='Une erreur est survenue lors du chargement de vos réponses' />
        );
    }
    return (
        <div className={`mt-1 whitespace-pre-line text-left text-gray-800`}>
            {status === 'generating' ? (
                <LinearLoaderDrawer percentage={percentage} />
            ) : null}
            <MatrixAnswerTable
                answers={textParsed}
                documents={files}
                isGenerating={isGenerating}
            />
        </div>
    );
};

export default MatriceMessage;
