'use client';
import React from 'react';
import JiminiButton from '@/components/common_V2/JiminiButton';

const MissionTrackerHeader = ({
    setAllMissionSeen,
}: {
    setAllMissionSeen: () => void;
}) => {
    return (
        <div className='flex h-[72px] w-full flex-row items-center justify-between border-b border-alphaGray6 px-4'>
            <h1 className='font-gilroySemiBold text-[20px] leading-[30px] text-solidGray12'>
                Notifications
            </h1>

            <JiminiButton
                variant='contained'
                onClick={setAllMissionSeen}
                backgroundColor='bg-alphaBlue3'
                textColor='text-solidBlue12'
                text='Tout marquer comme lu'
            />
        </div>
    );
};

export default MissionTrackerHeader;
