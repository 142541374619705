'use client';
import { Backdrop, Slide, Modal, TextField } from '@mui/material';
import { GeneralContext } from '@/components/Providers/GeneralContext';
import React, { useContext, useEffect } from 'react';
import Image from 'next/image';
import JiminiButton from '@/components/common_V2/JiminiButton';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import ModalErrorSnackbar from '@/components/common_V2/ModalErrorSnackbar';
import InfiniteLoadingCircle from '@/components/common_V2/InfiniteLoadingCircle';
import apiAnalyzer from '@/requests/apis/analyzer';
import apiDocuments from '@/requests/apis/documents';

const modalPreset = {
    conversation_name: {
        title: 'Renommer une conversation',
        titleCompute: () => '',
        fieldLabel: 'Nom de la conversation',
    },
    file_name: {
        title: '',
        titleCompute: (formerName: string) => `Renommer "${formerName}"`,
        fieldLabel: 'Nom',
    },
    new_file_name: {
        title: '',
        titleCompute: (formerName: string) => formerName,
        fieldLabel: 'Nom',
    },
};

const UpdateFieldModal = () => {
    const { updateModal, setUpdateModal } = useContext(GeneralContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [localValue, setLocalValue] = React.useState('');
    const [error, setError] = React.useState('');

    const {
        refetchConversationById,
        refetchLibraryByPath,
        refetchConversations,
        refetchDataroom,
    } = useJiminiQuery();

    useEffect(() => {
        if (updateModal?.initialValue) {
            setLocalValue(updateModal?.initialValue);
        }
    }, [updateModal]);

    const onSaveConversation = async (field_id: string) => {
        try {
            await apiAnalyzer.renameConversation({
                conversationId: field_id,
                name: localValue,
            });
            refetchConversationById(field_id);
            refetchConversations();
            onClose();
        } catch (err) {
            setError('Une erreur est survenue');
        }
        setIsLoading(false);
    };

    const onSaveFile = async (id: string, name: string, queryKey: string) => {
        try {
            await apiDocuments.rename({
                id,
                name,
            });
            refetchDataroom();
            await refetchLibraryByPath(queryKey);
            setIsLoading(false);
            onClose();
        } catch (e) {
            console.log('error', e);
            setError('Une erreur est survenue');
        }
        setIsLoading(false);
    };

    const onSave = () => {
        if (updateModal?.initialValue === localValue) {
            onClose();
            return;
        }
        setIsLoading(true);
        setError('');
        if (updateModal?.fieldType === 'conversation_name') {
            onSaveConversation(updateModal?.field_id);
        } else if (
            updateModal?.fieldType === 'file_name' ||
            updateModal?.fieldType === 'new_file_name'
        ) {
            if (updateModal?.initialValue === localValue) onClose();
            else
                onSaveFile(
                    updateModal?.field_id,
                    localValue,
                    updateModal?.queryKey
                );
        }
    };

    const onClose = () => {
        if (updateModal) {
            setUpdateModal({
                ...updateModal,
                updateFieldModalOpen: false,
            });
            setTimeout(() => {
                setUpdateModal({
                    updateFieldModalOpen: false,
                    initialValue: '',
                    field_id: '',
                    queryKey: '',
                    fieldType: 'conversation_name',
                });
            }, 500);
        }
    };
    const preset = updateModal?.fieldType
        ? modalPreset[updateModal.fieldType]
        : null;

    return (
        <Modal
            open={!!updateModal?.updateFieldModalOpen}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            className='flex items-center justify-center'
        >
            <Slide
                timeout={{ enter: 250, exit: 250 }}
                direction='down'
                in={!!updateModal?.updateFieldModalOpen}
                mountOnEnter
                unmountOnExit
            >
                <div className='z-10 flex h-[267px] w-[576px] flex-col justify-between rounded-xl bg-white p-6'>
                    <div className='flex flex-row items-center justify-between'>
                        <h1 className='text-left font-gilroySemiBold text-xl text-alphaGray12'>
                            {updateModal?.fieldType === 'file_name' ||
                            updateModal?.fieldType === 'new_file_name'
                                ? preset?.titleCompute(
                                      updateModal?.initialValue ?? ''
                                  )
                                : preset?.fieldLabel}
                        </h1>
                        <JiminiButton
                            variant='text'
                            onClick={onClose}
                            backgroundColor='bg-solidBlue12'
                            className='m-0 min-w-[42px] px-0'
                            iconLeft={
                                <Image
                                    priority
                                    src={
                                        '/images/V2/tabler/icon_close_cross.svg'
                                    }
                                    alt='add icon'
                                    width={20}
                                    height={20}
                                />
                            }
                        />
                    </div>

                    {error ? <ModalErrorSnackbar error={error} /> : null}

                    <div>
                        <h1 className='mb-3 text-left font-gilroySemiBold text-base text-solidGray12'>
                            {preset?.fieldLabel}
                        </h1>
                        <TextField
                            placeholder='Nom du dataset'
                            // disabled={disabledName}
                            variant='outlined'
                            onChange={(e) => setLocalValue(e.target.value)}
                            value={localValue}
                            className='flex w-full flex-col border-none font-gilroyTitle placeholder-gray-500 placeholder-opacity-20 placeholder:text-[12px] hover:outline-none focus:outline-none'
                            InputLabelProps={{ shrink: false }}
                            sx={{
                                padding: 0,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '6px',
                                    border: 'none',
                                    borderColor: '#B5B5B5',
                                },
                                '.MuiInputBase-root': {
                                    padding: '0px',
                                    paddingRight: '0px',
                                    border: 'none',
                                    backgroundColor: '#00449413',
                                },
                                '.MuiOutlinedInput-root:focus-within .MuiOutlinedInput-notchedOutline':
                                    {
                                        border: 'none',
                                        borderColor: '#0A0E45',
                                    },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-input':
                                    {
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        // fontSize: '14px',
                                        padding: '0px 8px',
                                        // minWidth: 'fit-content',
                                        fontFamily: 'Gilroy-Medium',
                                        color: '#6C6C6C',
                                        fontSize: '16px',
                                    },
                            }}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-row justify-end gap-2'>
                            <JiminiButton
                                variant='contained'
                                onClick={() => onClose()}
                                text='Annuler'
                                className='w-fit'
                                backgroundColor='bg-alphaBlue3'
                                textColor='text-solidBlue12'
                            />
                            <JiminiButton
                                variant='contained'
                                onClick={onSave}
                                text={isLoading ? '' : 'Sauvegarder'}
                                iconLeft={
                                    isLoading ? (
                                        <InfiniteLoadingCircle className='-mb-0.5' />
                                    ) : null
                                }
                                backgroundColor={
                                    isLoading
                                        ? 'bg-alphaBlue3'
                                        : 'bg-solidBlue12'
                                }
                                className={`w-[128px]`}
                            />
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};

export default UpdateFieldModal;
