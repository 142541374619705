'use client';
import React, { useContext } from 'react';
import { DocumentViewerContext } from '@/components/Providers/DocumentViewerProvider';
import Image from 'next/image';
import JiminiButton from '@/components/common_V2/JiminiButton';
import { DrawerContext } from '@/components/Providers/DrawerProvider';
import { UseCaseType } from '@/types/analyzer';
import { missionSpecificUiMapping } from '@/helpers/missions';

const AnswerDrawerMessageWrapper = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    const { documentDisplayed } = useContext(DocumentViewerContext);

    return (
        <div
            className={`relative px-6 transition-all duration-300 ease-in-out ${
                !!documentDisplayed ? 'w-[calc(64vw+40px)]' : 'w-[64vw]'
            } ${className ?? ''}`}
        >
            {children}
        </div>
    );
};
const AnswerDrawerTitle = ({
    type_question,
}: {
    type_question: UseCaseType;
}) => {
    const MissionIcon = missionSpecificUiMapping[type_question].Icon;
    return (
        <div className='flex flex-row items-center'>
            <MissionIcon
                width={24}
                height={24}
                className='stroke-solidGray12'
            />
            <h1 className='-mb-1 ml-1 font-gilroyTitle text-[20px] leading-[30px]'>
                {missionSpecificUiMapping[type_question]?.titleAnswerDrawer}
            </h1>
        </div>
    );
};

const AnswerDrawerCloseButton = () => {
    const { setMissionDrawerOpen } = useContext(DrawerContext);
    return (
        <JiminiButton
            variant='contained'
            onClick={() => setMissionDrawerOpen(false)}
            backgroundColor='bg-alphaBlue3'
            className='ml-1 px-3'
            textColor='text-solidBlue12'
            iconLeft={
                <Image
                    priority
                    src={'/images/V2/tabler/icon_close_cross.svg'}
                    alt='add icon'
                    width={22}
                    height={22}
                    className='mr-1.5'
                />
            }
            text='Fermer'
        />
    );
};
export {
    AnswerDrawerMessageWrapper,
    AnswerDrawerCloseButton,
    AnswerDrawerTitle,
};
