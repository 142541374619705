'use client';

import StyledTooltip from '@/components/common/Tooltip';
import TableCell from '@mui/material/TableCell';
import React from 'react';
import { AnswerMatrixRaw } from '@/types/analyzer';
import WarningBordered from '@/components/Icons/WarningBordered';
import ThreeLinesOneSmaller from '@/components/Icons/ThreeLinesOneSmaller';
import InfiniteLoadingCircle from '@/components/common_V2/InfiniteLoadingCircle';

const MatrixAnswerCellError = () => {
    return (
        <div className='flex flex-row items-center p-1.5'>
            <WarningBordered
                className='stroke-red-500'
                viewBox='0 0 22 22'
                height={20}
                width={20}
            />
            <span className='-mb-0.5 ml-2 font-gilroyMedium text-sm'>
                Une erreur est survenue
            </span>
        </div>
    );
};

const MatrixAnswerCellLoading = () => {
    return (
        <div className='flex items-center justify-center'>
            <div className='w-fit'>
                <InfiniteLoadingCircle />
            </div>
        </div>
    );
};

const LongAnswerTooltipMatrixCell = ({
    isImpossibleAnswer,
    children,
    answer,
}: {
    isImpossibleAnswer: boolean;
    children: React.ReactNode;
    answer?: AnswerMatrixRaw;
}) => {
    return (
        <StyledTooltip
            className={`m-0 ml-0 flex min-h-[96px] p-2 ${
                isImpossibleAnswer ? 'bg-solidGray1' : ''
            } min-w-full flex-1 items-center justify-start py-0 outline-none`}
            classes={{
                popper: 'border-none outline-none ',
                tooltip: 'border-none outline-none ',
            }}
            sx={{
                '& .MuiPaper-root': {
                    border: 'none',
                },
            }}
            title={answer?.detailed_answer?.replace(/\[(\d+)\]/g, '')}
            placement={'bottom'}
        >
            <div>{children}</div>
        </StyledTooltip>
    );
};

const ShortAnswerMatrixCell = ({
    isImpossibleAnswer,
    answer,
}: {
    isImpossibleAnswer?: boolean;
    answer?: AnswerMatrixRaw;
}) => {
    return (
        <div className='relative flex h-[58px] w-full max-w-[190px]'>
            <h2 className='pointer-events-none mx-2 line-clamp-3 overflow-hidden text-left'>
                {isImpossibleAnswer ? (
                    <div className='rounded-lg bg-alphaGray3 px-3 py-2 font-gilroySemiBold'>
                        Non trouvé
                    </div>
                ) : (
                    answer?.concise_answer
                )}
            </h2>
        </div>
    );
};

const MatrixAnswerCell = ({
    index,
    isGenerating,
    isErrored,
    handleOnClickCell,
    answer,
}: {
    index: number;
    isGenerating: boolean;
    isErrored: boolean;
    handleOnClickCell: () => void;
    answer: AnswerMatrixRaw;
}) => {
    // const isGenerating = false;

    const isImpossibleAnswer =
        answer?.concise_answer === 'Impossible de répondre';

    // const isErrored = true
    return (
        <TableCell
            key={index}
            className={`${
                isGenerating || isErrored ? '' : 'cursor-pointer'
            } relative m-0 min-w-[256px] max-w-[256px] border border-solidGray6 p-0`}
            onClick={() => handleOnClickCell()}
        >
            <LongAnswerTooltipMatrixCell
                isImpossibleAnswer={isImpossibleAnswer}
                answer={answer}
            >
                {isGenerating ? (
                    <MatrixAnswerCellLoading />
                ) : isErrored ? (
                    <MatrixAnswerCellError />
                ) : (
                    <ShortAnswerMatrixCell
                        isImpossibleAnswer={isImpossibleAnswer}
                        answer={answer}
                    />
                )}
            </LongAnswerTooltipMatrixCell>
            {!isGenerating && !isErrored && !isImpossibleAnswer ? (
                <ThreeLinesOneSmaller className='absolute bottom-1.5 right-1.5 stroke-solidGray6' />
            ) : null}
        </TableCell>
    );
};

export default MatrixAnswerCell;
