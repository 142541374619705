'use client';
import React from 'react';

const HamburgerMenu = ({ open, setIsOpen }: any) => {
    const genericHamburgerLine = `h-1 w-6 my-0.5 rounded-full bg-grey2 transition ease transform duration-200`;
    return (
        <button
            className='flex h-12 w-12 flex-col items-center justify-center rounded border border-grey2'
            onClick={() => setIsOpen(!open)}
        >
            <div
                className={`${genericHamburgerLine} ${
                    open
                        ? 'translate-y-2 rotate-45 group-hover:opacity-100'
                        : 'opacity-100 group-hover:opacity-100'
                }`}
            />
            <div
                className={`${genericHamburgerLine} ${
                    open ? 'opacity-0' : 'opacity-`100 group-hover:opacity-100'
                }`}
            />
            <div
                className={`${genericHamburgerLine} ${
                    open
                        ? '-translate-y-2 -rotate-45 group-hover:opacity-100'
                        : 'opacity-100 group-hover:opacity-100'
                }`}
            />
        </button>
    );
};

export default HamburgerMenu;
