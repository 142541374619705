const sxOverrideSearchADBSortPicker = {
    '& .MuiSelect-select.MuiSelect-outlined': {
        padding: '0px',
        paddingRight: '2px',
    },
    '& .MuiSelect-icon': {
        display: 'none',
    },
    border: 'none',
    borderRadius: '999px',
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': {
        borderStyle: 'none',
    },
    '.MuiMenu-list': {
        paddingRight: '0px',
    },
    '& .MuiPaper-root': {
        border: 'none',
        outline: 'none',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '2px',
    },
    minWidth: '120px',
};

const MenuPropsOverrideSearchADBSortPicker = {
    classes: {
        paper: 'border border-solid border-[#DBE2EC] rounded-[6px] shadow-[0px_10px_15px_-3px_rgba(0,0,0,0.1)]',
    },
};

const sxOverrideSearchADBSortPickerMenuItem = {
    '.MuiPaper-root': {
        marginLeft: 0,
    },
    '& .MuiTouchRipple-root': {
        color: '#63B6FF',
    },
    '.MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
    },
};

export {
    sxOverrideSearchADBSortPicker,
    MenuPropsOverrideSearchADBSortPicker,
    sxOverrideSearchADBSortPickerMenuItem,
};
