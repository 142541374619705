'use client';
import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from 'react';
import { FileUploadFormatted } from '@/types/files';
import { getFileType, isValidMimeType } from '@/helpers/library/uploadFile';
import { PublicLibraryFile } from '@/types/requests';
import { UploadableFile } from '@/types/connectors';

type UploadContextType = {
    // is uploading = used in various areas to prevent leaving a page while uploading
    isUploading: boolean;
    setIsUploading: Dispatch<SetStateAction<boolean>>;
    // files are being uploaded one by one , so that we can cancel the upload of a file if needed
    currentFileUploading: FileUploadFormatted | null;
    setCurrentFileUploading: Dispatch<
        SetStateAction<FileUploadFormatted | null>
    >;
    // files uploading = list of files that are currently uploading, displayed in the upload snackbar and cancelled
    // if removed from this list before being sent to currentFileUploading
    filesUploading: FileUploadFormatted[];
    setFilesUploading: Dispatch<SetStateAction<FileUploadFormatted[]>>;
    // pending selected = to refresh the list of selected files on dashboard when their state change (embedding, error etc)
    filesPendingSelected: PublicLibraryFile[];
    setFilesPendingSelected: Dispatch<SetStateAction<PublicLibraryFile[]>>;
    downloadEnded: boolean;
    setDownloadEnded: Dispatch<SetStateAction<boolean>>;
    errorUploadQuota: null | 'files' | 'size';
    setErrorUploadQuota: Dispatch<SetStateAction<null | 'files' | 'size'>>;
    snackbarUploadingOpen: boolean;
    setSnackbarUploadingOpen: Dispatch<SetStateAction<boolean>>;
    onFilePick: (
        files: UploadableFile[],
        path: string,
        alreadyProcessedFiles?: boolean,
        library?: string
    ) => void;
};

export const UploadContext = createContext<UploadContextType>({
    isUploading: false,
    setIsUploading: () => {},
    currentFileUploading: null,
    setCurrentFileUploading: () => {},
    filesUploading: [],
    setFilesUploading: () => {},
    filesPendingSelected: [],
    setFilesPendingSelected: () => {},
    downloadEnded: false,
    setDownloadEnded: () => {},
    errorUploadQuota: null,
    setErrorUploadQuota: () => {},
    snackbarUploadingOpen: false,
    setSnackbarUploadingOpen: () => {},
    onFilePick: () => {},
});

const UploadProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [snackbarUploadingOpen, setSnackbarUploadingOpen] =
        useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [currentFileUploading, setCurrentFileUploading] =
        useState<FileUploadFormatted | null>(null);
    const [downloadEnded, setDownloadEnded] = useState<boolean>(false);
    const [filesPendingSelected, setFilesPendingSelected] = useState<
        PublicLibraryFile[]
    >([]);
    const [filesUploading, setFilesUploading] = useState<FileUploadFormatted[]>(
        []
    );
    const [errorUploadQuota, setErrorUploadQuota] = useState<
        null | 'files' | 'size'
    >(null);

    const onFilePick = async (
        files: UploadableFile[],
        path: string,
        alreadyProcessedFiles?: boolean,
        library?: string
    ) => {
        setSnackbarUploadingOpen(true);
        const processedFiles = alreadyProcessedFiles
            ? files
            : processFiles(files);
        setFilesUploading(
            convertToFilesUploading(processedFiles, library, path)
        );
        setIsUploading(true);
    };

    return (
        <UploadContext.Provider
            value={{
                isUploading,
                setIsUploading,
                currentFileUploading,
                setCurrentFileUploading,
                downloadEnded,
                setDownloadEnded,
                filesPendingSelected,
                setFilesPendingSelected,
                filesUploading,
                setFilesUploading,
                errorUploadQuota,
                setErrorUploadQuota,
                snackbarUploadingOpen,
                setSnackbarUploadingOpen,
                onFilePick,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};
export default UploadProvider;

function processFiles(files: UploadableFile[]): UploadableFile[] {
    return files.filter((file) => isValidMimeType(file?.type));
}

function convertToFilesUploading(
    files: UploadableFile[],
    library: string | undefined,
    path: string
): FileUploadFormatted[] {
    return files.map((file: UploadableFile, id: number) => ({
        id,
        file,
        name: file.name,
        type: getFileType(file),
        uploading: true,
        uploaded: false,
        org_id: library === 'personal' ? undefined : 'lawfirm',
        path,
    }));
}
